import axios from '../../utils/axios';
import { LIMIT } from '../../utils/config';

const WalletService = {

  async getListWallets(page = 1, limit = LIMIT, search = '') {
      try{
        const response = await axios.get('/admin/wallets', {
          params: {
            page,
            limit,
            search,
          }
        })
        return response.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
  },
  async getById(id) {
      try {
        const response = await axios.get(`/admin/wallets/${id}`);
        return response.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
  },
  async getByAddress(address) {
      try {
        const response = await axios.get(`/admin/wallets?search=${address}`);
        return response.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
  },  
  async getTransactionbyAddress(user_id, page = 1, limit = LIMIT) {
    try {
      const response = await axios.get(`/admin/wallets/${user_id}/transactions?page=${page}&limit=${limit}`);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
}

export default WalletService;