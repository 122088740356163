import {
  AUTHENTICATE_FAILURE,
  AUTHENTICATE_SUCCESS,
  LOGOUT_SUCCESS,
  LOGIN_DOING,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_FAILURE,
  SEND_EMAIL_FORGET_SUCCESS,
  SEND_EMAIL_FORGET_FAILURE,
  VERIFY_CODE_FORGET_PASSWORD_SUCCESS,
  VERIFY_CODE_FORGET_PASSWORD_FAILURE,
  UPDATE_PASSWORD_SA_SUCCESS,
  UPDATE_PASSWORD_SA_FAILURE
} from './AuthAction';

const initialState = {
  waiting: true,
  error: '',
  loading: false,
  authenticated: false,
  user: null,
  token: '',
  isChangePassword: false,
  isShowLogin: false
}

const authReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case AUTHENTICATE_SUCCESS: {
      return {
        ...state,
        waiting: false,
        authenticated: true,
        user: action.payload
      }
    }

    case AUTHENTICATE_FAILURE: {
      return {
        ...state,
        waiting: false,
        authenticated: false
      }
    }

    case LOGIN_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case LOGIN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case LOGIN_SUCCESS: {
      return {
        ...state,
        waiting: false,
        authenticated: true,
        loading: false,
        user: action.payload.user
      }
    }

    case LOGOUT_SUCCESS: {
      return initialState;
    }

    case VERIFY_TOKEN_SUCCESS: {
      return {
        ...state,
        token: action.payload,
        loading: false,
      }
    }

    case VERIFY_TOKEN_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case SEND_EMAIL_FORGET_SUCCESS: {
      return {
        ...state,
        token: action.payload,
        loading: false,
      }
    }

    case SEND_EMAIL_FORGET_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case VERIFY_CODE_FORGET_PASSWORD_SUCCESS: {
      return {
        ...state,
        token: action.payload,
        loading: false,
        isChangePassword: true,
      }
    }

    case VERIFY_CODE_FORGET_PASSWORD_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case UPDATE_PASSWORD_SA_SUCCESS: {
      return {
        ...state,
        loading: false,
        isChangePassword: false,
        isShowLogin: true,
        token: ""
      }
    }

    case UPDATE_PASSWORD_SA_FAILURE: {
      return {
        ...state,
        loading: false,
        isChangePassword: false,
      }
    }

    default:
      return state;
  }
}

export default authReducer;

