import {
    GET_LIST_WALLETS_DOING,
    GET_LIST_WALLETS_SUCCESS,
    GET_LIST_WALLETS_FAILURE,
    GET_LIST_TRANSACTION_FAILURE,
    GET_LIST_TRANSACTION_DOING,
    GET_LIST_TRANSACTION_SUCCESS
} from './WalletAction';

const initialState = {
  loading: false,
  error: '',
  wallets: [],
  total: 0
}

const walletReducer = (state = initialState, action = {}) => {
  switch(action.type) {
    case GET_LIST_WALLETS_DOING : {
      return {
        ...state,
        loading: true
      }
    }

    case GET_LIST_WALLETS_SUCCESS: {
      return {
        ...state,
        loading: false,
        wallets: action.payload.results,
        total: action.payload.total
      }
    }

    case GET_LIST_WALLETS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }
    
    case GET_LIST_TRANSACTION_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_LIST_TRANSACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        transactions: action.payload
      }
    }

    case GET_LIST_TRANSACTION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    default:
      return state;
  }
}

export default walletReducer;