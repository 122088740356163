import axios from '../../utils/axios';
import { LIMIT, STATUS, USER_RECEIVED_NEWS, USER_ROLE } from '../../utils/config';

const UserService = {

  async getListUsers(status = STATUS.all, isReceived = STATUS.all, page = 1, limit = LIMIT, search = '') {
    try {

      const response = await axios.get('/admin/users', {
        params: {
          page,
          limit,
          status,
          isReceived,
          search,
          role: USER_ROLE.user
        }
      })

      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async getById(id) {
    try {
      const response = await axios.get(`/users/${id}`);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async deleteById(id) {
    try {
      const response = await axios.delete(`/admin/users/${id}`);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async create(model) {
    try {
      const response = await axios.post('/admin/users', model);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Update by id
   * @param {number} id id of user
   * @param {Object} model data to update
   */
  async updateById(id, model) {
    try {
      const response = await axios.put(`/admin/users/${id}`, model);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async editNoteUser(id, note) {
    const params = {
      note
    }
    const response = await axios.patch(`/admin/users/${id}/note`, params);
    return response.data.data
  }
}

export default UserService;
