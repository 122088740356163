import ChallengeService from './ChallengeService';

export const GET_LIST_CHALLENGE_SUCCESS = 'GET_LIST_CHALLENGE_SUCCESS';
const getListChallengeSuccess = (result) => {
    return { type: GET_LIST_CHALLENGE_SUCCESS, payload: result }
}

export  const GET_LIST_CHALLENGE_DOING = 'GET_LIST_CHALLENGE_DOING';
const getListChallengeDoing = (result) => {
    return { type: GET_LIST_CHALLENGE_DOING, payload: result }
}

export const GET_LIST_CHALLENGE_FAILURE = 'GET_LIST_CHALLENGE_FAILURE';
const getListChallengeFail = (error) => {
    return { type: GET_LIST_CHALLENGE_FAILURE, error }
}

export const GET_LIST_CHALLENGE_EXPORT_SUCCESS = 'GET_LIST_CHALLENGE_EXPORT_SUCCESS';
const getListChallengeExportSuccess = (result) => {
    return { type: GET_LIST_CHALLENGE_EXPORT_SUCCESS, payload: result }
}

export  const GET_LIST_CHALLENGE_EXPORT_DOING = 'GET_LIST_CHALLENGE_EXPORT_DOING';
const getListChallengeExportDoing = (result) => {
    return { type: GET_LIST_CHALLENGE_EXPORT_DOING, payload: result }
}

export const GET_LIST_CHALLENGE_EXPORT_FAILURE = 'GET_LIST_CHALLENGE_EXPORT_FAILURE';
const getListChallengeExportFail = (error) => {
    return { type: GET_LIST_CHALLENGE_EXPORT_FAILURE, error }
}

/**
 * @param {number} type type get challenges. 1: sponsor. 2: challenger
 * @param {number} page page number
 * @param {number} limit limit number
 */
export const getListChallenge = (type, page, limit, search) => {
    return async dispatch => {

      dispatch(getListChallengeDoing());

      return ChallengeService.getListChallenge(type, page, limit, search)
      .then(response => {
        if (!response.error) {
            return dispatch(getListChallengeSuccess(response.data));
        }

        return dispatch(getListChallengeFail(response));
      })
    }
}

export const getListChallengeExport = (type, page, limit, search) => {
    return async dispatch => {

      dispatch(getListChallengeExportDoing());

      return ChallengeService.getListChallengeExport(type, page, limit, search)
      .then(response => {
        if (!response.error) {
            return dispatch(getListChallengeExportSuccess(response.data));
        }

        return dispatch(getListChallengeExportFail(response));
      })
    }
}