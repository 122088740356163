import ExercisePackageService from './ExercisePackageService';
import { toast } from 'react-toastify';
import { getMessageById } from '../Multilang/locales/index';

/** Get list users action */
export const GET_LIST_EXERCISE_PACKAGES_SUCCESS = 'GET_LIST_EXERCISE_PACKAGES_SUCCESS';
const getListExercisePackagesSuccess = (result) => {
  return { type: GET_LIST_EXERCISE_PACKAGES_SUCCESS, payload: result}
}

export const GET_LIST_EXERCISE_PACKAGES_DOING = 'GET_LIST_EXERCISE_PACKAGES_DOING';
const getListExercisePackagesDoing = () => {
  return { type: GET_LIST_EXERCISE_PACKAGES_DOING }
}

export const GET_LIST_EXERCISE_PACKAGES_FAILURE = 'GET_LIST_EXERCISE_PACKAGES_FAILURE';
const getListExercisePackagesFail = (error) => {
  return { type: GET_LIST_EXERCISE_PACKAGES_FAILURE, error }
}

/**
 * @param {number} page page number
 * @param {number} limit limit number
 */
export const getListExercisePackages = (page, limit, search) => {
  return async dispatch => {

    dispatch(getListExercisePackagesDoing());

    return ExercisePackageService.getListExercisePackages(page, limit, search)
      .then(response => {
        if (!response.error) {
          return dispatch(getListExercisePackagesSuccess(response.data));
        }

        return dispatch(getListExercisePackagesFail(response));
      })
  }
}

/** Delete by id */
export const DELETE_EXERCISE_PACKAGE_SUCCESS = 'DELETE_EXERCISE_PACKAGE_SUCCESS';
const deleteByIdSuccess = (result) => {
  toast.success(getMessageById('app.message.exercise.delete_success'));
  return { type: DELETE_EXERCISE_PACKAGE_SUCCESS, payload: result }
}

export const DELETE_EXERCISE_PACKAGE_DOING = 'DELETE_EXERCISE_PACKAGE_DOING';
const deleteByIdDoing = () => {
  return { type: DELETE_EXERCISE_PACKAGE_DOING }
}

export const DELETE_EXERCISE_PACKAGE_FAILURE = 'DELETE_EXERCISE_PACKAGE_FAILURE';
const deleteByIdFail = (error) => {
  toast.error(error.message);
  return { type: DELETE_EXERCISE_PACKAGE_FAILURE, error }  
}

export const deleteById = (id) => {
  return async dispatch => {
    dispatch(deleteByIdDoing());

    return ExercisePackageService.deleteById(id)
      .then(response => {
        if (!response.error) {
          return dispatch(deleteByIdSuccess({ id }));
        }

        return dispatch(deleteByIdFail(response));
      })
  }
}

/** Create */
export const CREATE_EXERCISE_PACKAGE_SUCCESS = 'CREATE_EXERCISE_PACKAGE_SUCCESS';
const createSuccess = (result) => {
  toast.success(getMessageById('app.message.exercise.create_success'));
  return { type: CREATE_EXERCISE_PACKAGE_SUCCESS, payload: result }
}

export const CREATE_EXERCISE_PACKAGE_DOING = 'CREATE_EXERCISE_PACKAGE_DOING';
const createDoing = () => {
  return { type: CREATE_EXERCISE_PACKAGE_DOING }
}

export const CREATE_EXERCISE_PACKAGE_FAILURE = 'CREATE_EXERCISE_PACKAGE_FAILURE';
const createFail = (error) => {
  toast.error(error.message);
  return { type: CREATE_EXERCISE_PACKAGE_FAILURE, error }  
}

export const create = (model) => {
  return async dispatch => {
    dispatch(createDoing());

    return ExercisePackageService.create(model)
      .then(response => {
        if (!response.error) {
          return dispatch(createSuccess(response.data));
        }

        return dispatch(createFail(response));
      })
  }
}

/** Update */
export const UPDATE_EXERCISE_PACKAGE_SUCCESS = 'UPDATE_EXERCISE_PACKAGE_SUCCESS';
const updateByIdSuccess = (result) => {
  toast.success(getMessageById('app.message.exercise.update_success'));
  return { type: UPDATE_EXERCISE_PACKAGE_SUCCESS, payload: result }
}

export const UPDATE_EXERCISE_PACKAGE_DOING = 'UPDATE_EXERCISE_PACKAGE_DOING';
const updateByIdDoing = () => {
  return { type: UPDATE_EXERCISE_PACKAGE_DOING }
}

export const UPDATE_EXERCISE_PACKAGE_FAILURE = 'UPDATE_EXERCISE_PACKAGE_FAILURE';
const updateByIdFail = (error) => {
  toast.error(error.message);
  return { type: UPDATE_EXERCISE_PACKAGE_FAILURE, error }  
}

export const updateById = (id, model) => {
  return async dispatch => {
    dispatch(updateByIdDoing());

    return ExercisePackageService.updateById(id, model)
      .then(response => {
        if (!response.error) {
          return dispatch(updateByIdSuccess(response.data));
        }

        return dispatch(updateByIdFail(response));
      })
  }
}

