import {
    GET_DAILY_STEP_DOING,
    GET_DAILY_STEPS_SUCCESS,
    GET_DAILY_STEP_FAILURE
} from './DailyStepAction';

const initialState = {
  loading: false,
  error: '',
  dailySteps: [],
  total: 0
}

const challengeReducer = (state = initialState, action = {}) => {
  switch(action.type) {
    case GET_DAILY_STEP_DOING : {
      return {
        ...state,
        loading: true
      }
    }

    case GET_DAILY_STEPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        dailySteps: action.payload.results,
        total: action.payload.total
      }
    }

    case GET_DAILY_STEP_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }
    
    default:
      return state;
  }
}

export default challengeReducer;