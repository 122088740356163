import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./components/Layout'));
const DefaultLayoutPrompt = React.lazy(() => import('./components/LayoutPrompt'));
const Login = React.lazy(() => import('./components/Auth/Login'));
const PrivateRoute = React.lazy(() => import('./components/Auth/PrivateRoute'));
const Register = React.lazy(() => import('./components/Pages/Register'));
const Page404 = React.lazy(() => import('./components/Pages/Page404'));
const PageAppstore = React.lazy(() => import('./components/Pages/PageAppstore/PageAppstore.js'));
const Page500 = React.lazy(() => import('./components/Pages/Page500'));

class App extends Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
  }

  render() {
    return (
      <BrowserRouter  ref={this.wrapper}>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/app-store" name="Login Page" render={props => <PageAppstore {...props} />} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <PrivateRoute path="/admins-gpt" name="Prompt" component={DefaultLayoutPrompt}/>
            <PrivateRoute path="/" name="Home" component={DefaultLayout}/>
          </Switch>
          <ToastContainer draggable={false} />
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
