import DeployService from './DeployService';

export const GET_STATUS_DEPLOY_SUCCESS = 'GET_STATUS_DEPLOY_SUCCESS';
const getStatusDeploySuccess = (result) => {
    return { type: GET_STATUS_DEPLOY_SUCCESS, payload: result }
}

export  const GET_STATUS_DEPLOY_DOING = 'GET_STATUS_DEPLOY_DOING';
const getStatusDeployDoing = (result) => {
    return { type: GET_STATUS_DEPLOY_DOING, payload: result }
}

export const GET_STATUS_DEPLOY_FAILURE = 'GET_STATUS_DEPLOY_FAILURE';
const getStatusDeployFail = (error) => {
    return { type: GET_STATUS_DEPLOY_FAILURE, error }
}

/**
 * @param {number} type type get challenges. 1: sponsor. 2: challenger
 * @param {number} page page number
 * @param {number} limit limit number
 */
export const getStatusDeploy = () => {
    return async dispatch => {

      dispatch(getStatusDeployDoing());

      return DeployService.getById()
      .then(response => {
        if (!response.error) {
            return dispatch(getStatusDeploySuccess(response.data));
        }

        return dispatch(getStatusDeployFail(response));
      })
    }
}
