import { LANG } from '../../../utils/config';

export const ENGLISH = {
  lang: LANG.en,
  messages: {
    // Header
    'app.header.lang': 'en',
    'app.header.hello': 'Hello!',
    'app.header.setting': 'Setting',
    'app.header.logout': 'Logout',

    // Sidebar
    'app.sidebar.home': 'Home',
    'app.sidebar.users': 'Users',
    'app.sidebar.admins': 'Admin',
    'app.sidebar.exercisePackages': 'Exercise packages',
    'app.sidebar.banners': 'Banners',
    'app.sidebar.news': 'News',
    'app.sidebar.versions': 'Versions',
    'app.sidebar.challenges': 'Challenges',
    'app.sidebar.reports': 'Reports',
    'app.sidebar.wallets': 'Wallets',
    'app.sidebar.contractVersions': 'Contract versions',
    'app.sidebar.deploy': 'Deploy',
    'app.sidebar.listPrompt': 'Get list prompt',
    'app.sidebar.recommendation': 'Recommendation',
    'app.sidebar.cheer': 'Cheering & Motivation',
    'app.sidebar.question': 'Question',
    'app.sidebar.newPrompt': 'Add new prompt',
    'app.sidebar.tanimoto.token': 'TanimoToken',
    'app.sidebar.dailyStep': 'Daily Steps',
    'app.sidebar.networks': 'Networks',

    //Admin
    'app.body.admins.createAdmin': 'Create Admin',
    'app.body.admins.adminDetail': 'Admin detail',
    'app.body.admins.adminEdit': 'Admin edit',

    // User
    'app.body.users.id': 'User ID',
    'app.body.users.createUser': 'Create User',
    'app.body.users.userDetail': 'User detail',
    'app.body.users.userEdit': 'User edit',
    'app.body.users.updateUser': 'Update User',
    'app.body.users.role': 'Role',
    'app.body.users.role.user': 'User',
    'app.body.users.role.admin': 'Admin',
    'app.body.users.role.superAdmin': 'Super Admin',
    'app.body.users.status.active': 'Active',
    'app.body.users.status.inactive': 'Inactive',
    'app.body.users.status.receive': 'Receiving',
    'app.body.users.status.notReceive': 'Not Receiving',
    'app.body.users.status.block': 'Block',
    'app.body.users.status.all': 'All',
    'app.body.users.userName': 'User Name',
    'app.body.users.userWalllet': 'Wallet Address',
    'app.body.users.email': 'Email',
    'app.body.users.qr_code': 'QR Code',
    'app.body.users.device': 'Device',
    'app.body.users.phoneNumber': 'Phone number',
    'app.body.users.enterPhoneNumber': 'Enter phone number',
    'app.body.users.enterUserName': 'Enter user name',
    'app.body.users.enterEmail': 'Enter email',
    'app.body.users.password': 'Password',
    'app.body.users.confirmPass': 'Comfirm password',
    'app.body.users.enterPassword': 'Enter password',
    'app.body.users.showPassword': 'Show password',
    'app.body.users.errPass': 'Wrong password confirmation',
    'app.body.users.errPhone': 'Unknown phone number',
    'app.body.users.register_step.registerEmail': 'Register Email',
    'app.body.users.register_step.enterPinCode': 'Enter PinCode',
    'app.body.users.register_step.agreeTermOfUse': 'Agree Term Of Use',
    'app.body.users.register_step.configTFA': 'Config TFA',
    'app.body.users.register_step.finish': 'Finish',
    'app.body.users.is_update_password.false': 'False',
    'app.body.users.is_update_password.true': 'True',
    'app.body.users.new_pass': 'New Password',
    'app.body.users.confirm_pass': 'Confirm Password',

    // config follow database
    'app.body.users.username': 'User Name',
    'app.body.users.birthday': 'Birthday',
    'app.body.users.height': 'Height',
    'app.body.users.weight': 'Weight',
    'app.body.users.phone_number': 'Phone number',
    'app.body.users.status': 'Status',
    'app.body.users.avatar': 'Avatar',
    'app.body.users.register_step': 'Register step',
    'app.body.users.is_update_password': 'Is update password',
    'app.body.users.is_deploying': 'App is deploying',
    'app.body.users.created_at': 'Created at',
    'app.body.users.updated_at': 'Updated at',
    'app.body.users.userSetting': 'User setting',
    'app.body.users.statusFilter': 'Status filter',
    'app.body.users.receivedNewsFilter': 'Received news',
    'app.body.users.delete': ' Warning',
    'app.body.users.notification': 'Do you want to delete user ID:',

    // Exercise packages
    'app.body.exercise.createExercise': 'Create exercise package',
    'app.body.exercise.exerciseDetail': 'Exercise package detail',
    'app.body.exercise.exerciseEdit': 'Exercise package edit',
    'app.body.exercise.updateWork': 'Update work',
    'app.body.exercise.id': 'Package ID',
    'app.body.exercise.name': 'Package Name',
    'app.body.exercise.description': 'Description',
    'app.body.exercise.amount': 'Deposit Amount (ETH)',
    'app.body.exercise.execute_time': 'Maximum Challenge Days (days)',
    'app.body.exercise.type_challenge': 'Type of Exercise',
    'app.body.exercise.daily_min_step_require': 'Target Steps (1day)',
    'app.body.exercise.min_day_require': 'Minimum Target Achievement Days (days)',
    'app.body.exercise.gas_fee': 'GAS Fee to Challenger (ETH)',
    'app.body.exercise.sponsor': 'Sponsor',
    'app.body.exercise.challenger': 'Challenger',
    'app.body.exercise.administrator_fee': 'Administrator Fee (ETH)',
    'app.body.exercise.fee_success_percentage': 'Service Fee percentage (Success)',
    'app.body.exercise.fee_success': 'Service Fee (Success)',
    'app.body.exercise.fee_error_percentage': 'Service Fee  percentage (Failure)',
    'app.body.exercise.fee_error': 'Service Fee (Failure)',
    'app.body.exercise.give_up': 'Give up',
    'app.body.exercise.give_up_pattern': 'Give up Pattern',
    'app.body.exercise.created_at': 'Created at',
    'app.body.exercise.options': 'Options',
    'app.body.exercise.is_setting_give_up': 'Is setting give up',
    'app.body.exercise.enterName': 'Enter name',
    'app.body.exercise.enterDescription': 'Enter description',
    'app.body.exercise.defaultType': 'Walking',
    'app.body.exercise.updated_at': 'Package Updated',
    'app.body.exercise.date_start': 'Date start',
    'app.body.exercise.date_end': 'Date end',
    'app.body.exercise.create_at': 'Package Created',
    'app.body.exercise.err_amount': 'Amount incorrect',
    'app.body.exercise.err_gas_free': 'Gas fee incorrect',
    'app.body.exercise.err_administrator_fee': 'Administrator Fee incorrect',
    'app.body.exercise.err_execute_time': 'Execute Time incorrect',
    'app.body.exercise.err_min_day_require': 'Min day require incorrect',
    'app.body.exercise.err_daily_min_step_require': 'Daily min step incorrect',
    'app.body.exercise.err_total_fee': 'Total fee invalid',
    'app.body.exercise.delete': ' Warning',
    'app.body.exercise.notification': 'Do you want to delete exercise package ID:',

    // Banners
    'app.body.banners': 'Banners',
    'app.body.banners.id': 'ID',
    'app.body.banners.createBanner': 'Create banner',
    'app.body.banners.bannerDetail': 'Banner detail',
    'app.body.banners.bannerEdit': 'Banner edit',
    'app.body.banners.title': 'Title',
    'app.body.banners.enter_title': 'Enter title',
    'app.body.banners.banner': 'Banner',
    'app.body.banners.status': 'Status',
    'app.body.banners.status.disabled': 'Disable',
    'app.body.banners.status.enabled': 'Enable',
    'app.body.banners.updated_at': 'Update at',
    'app.body.banners.description': 'Description',
    'app.body.banners.image_url': 'Image url',
    'app.body.banners.created_at': 'Created at',
    'app.body.banners.options': 'Options',
    'app.body.banners.create_type': 'Create banner from a file or url.',
    'app.body.banners.delete': ' Warning',
    'app.body.banners.notification': 'Do you want to delete banner ID:',

    // News
    'app.body.news.listNews': 'List news',
    'app.body.news.createNews': 'Create news',
    'app.body.news.newDetail': 'News detail',
    'app.body.news.id': 'ID',
    'app.body.news.name': 'Name',
    'app.body.news.title': 'Title',
    'app.body.news.enter_title': 'Enter title',
    'app.body.news.status': 'Status',
    'app.body.news.status.disabled': 'Disable',
    'app.body.news.status.enabled': 'Enable',
    'app.body.news.type.showAll': 'Show All',
    'app.body.news.type.showNormal': 'Show Normal',
    'app.body.news.url': 'Url news',
    'app.body.news.enter_url': 'Enter url news',
    'app.body.news.banner': 'Banner',
    'app.body.news.created_at': 'Created at',
    'app.body.news.updated_at': 'Update at',
    'app.body.news.user_id': 'User ID',
    'app.body.news.enterNewsName': 'Enter news name',
    'app.body.news.delete': ' Warning',
    'app.body.news.notification': 'Do you want to delete news ID:',
    'app.body.news.newEdit': 'News edit',

    // Versions
    'app.body.versions.versionCreate': 'Create version',
    'app.body.versions.versionDetail': 'Version detail',
    'app.body.versions.versionEdit': 'Version edit',
    'app.body.versions.name': 'Name',
    'app.body.versions.banner': 'Banner',
    'app.body.versions.id': 'ID',
    'app.body.versions.version': 'Version',
    'app.body.versions.platform': 'Platform',
    'app.body.versions.environment': 'Environment',
    'app.body.versions.type': 'Type',
    'app.body.versions.is_default': 'Is Default',
    'app.body.versions.created_at': 'Created at',
    'app.body.versions.updated_at': 'Update at',
    'app.body.environment.1': 'DEVELOP',
    'app.body.environment.2': 'STAGING',
    'app.body.environment.3': 'PRODUCT',
    'app.body.versions.type.0': 'Optional',
    'app.body.versions.type.1': 'ForceUpdate',
    'app.body.versions.delete': ' Warning',
    'app.body.versions.notification': 'Do you want to delete version ID:',

    // Challenges
    'app.body.challenges.challengeDetail': 'Challenge detail',
    'app.body.challenges.id': 'Challenge ID',
    'app.body.challenges.name_challenge': 'Challenge Name',
    'app.body.challenges.status': 'Status',
    'app.body.challenges.amount': 'Deposit Amount',
    'app.body.challenges.execute_time': 'Maximum Challenge Days (days)',
    'app.body.challenges.type_challenge': 'Challenge Created',
    'app.body.challenges.type_of_exercise': 'Type of Exercise',
    'app.body.challenges.sponsor': 'Sponsor',
    'app.body.challenges.challenger_name': 'Challenger',
    'app.body.challenges.period_from': 'Challenge Period From',
    'app.body.challenges.period_to': 'Challenge Period To',
    'app.body.challenges.hash': 'hash',
    'app.body.challenges.address': 'Address',
    'app.body.challenges.contract_address': 'Contract Address',
    'app.body.challenges.network': 'Network',
    'app.body.challenges.give_up_hash': 'Give up hash',
    'app.body.challenges.close_challenge_hash': 'Close challenge hash',
    'app.body.challenges.destroy_hash': 'Destroy hash',
    'app.body.challenges.signature_data': 'Signature data',
    'app.body.challenges.sponsor_email': 'Sponsor email',
    'app.body.challenges.sponsor_username': 'Sponsor username',
    'app.body.challenges.sponsor_wallet_address': 'Sponsor Wallet address',
    'app.body.challenges.sponsor_birthday': 'Sponsor birthday',
    'app.body.challenges.sponsor_phone_number': 'Sponsor phone number',
    'app.body.challenges.register_step': 'Register step',
    'app.body.challenges.challenger_email': 'Challenger email',
    'app.body.challenges.challenge_username': 'Challenger username',
    'app.body.challenges.challenge_wallet_address': 'Challenger Wallet address',
    'app.body.challenges.challenger_birthday': 'Challenger birthday',
    'app.body.challenges.challenger_phone_number': 'Challenger phone number',
    'app.body.challenges.gas_limit': 'Gas limit',
    'app.body.challenges.low': 'Low',
    'app.body.challenges.hight': 'Hight',
    'app.body.challenges.transaction_fee': 'Transaction fee',
    'app.body.challenges.warning': 'Warning!',
    'app.body.challenges.options_delete': 'Do you really want to delete the challenge?',
    'app.body.challenges.confirm_infor': 'Confirm information',
    'app.body.challenges.private_key': 'Private key',
    'app.body.challenges.timezone': 'Timezone',
    'app.body.challenges.enter_private_key': 'Enter private key',
    'app.body.challenges.enter_address': 'Enter address',
    'app.body.challenges.receive.id': 'Receiver ID',
    'app.body.challenges.receive.amount': 'Receiver amount',
    'app.body.challenges.receive.type': 'Receiver Type',
    'app.body.challenges.receive.type.1': 'Success',
    'app.body.challenges.receive.type.2': 'Failure',
    'app.body.challenges.receive.wallet_address': 'Receiver Wallet address',
    'app.body.challenges.receive.dividend_percentage': 'Dividend (percentage)',
    'app.body.challenges.receive.dividend_amount': 'Dividend (amount)',
    'app.body.challenges.receive.username': 'Receiver user name',
    'app.body.challenges.receive.success': '(Success)',
    'app.body.challenges.receive.failure': '(Failure)',
    'app.body.challenges.register_step.registerEmail': 'Register Email',
    'app.body.challenges.register_step.enterPinCode': 'Enter PinCode',
    'app.body.challenges.register_step.agreeTermOfUse': 'Agree Term Of Use',
    'app.body.challenges.register_step.configTFA': 'Config TFA',
    'app.body.challenges.register_step.finish': 'Finish',
    'app.body.challenges.register_step.0': 'False',
    'app.body.challenges.register_step.1': 'True',
    'app.body.challenges.stepInfo.date':'Date',
    'app.body.challenges.stepInfo.pending':'Pending',
    'app.body.challenges.stepInfo.success':'Successful',
    'app.body.challenges.stepInfo.fail':'Failure',
    'app.body.challenges.stepInfo.standardTime':'Standard Time',
    'app.body.challenges.stepInfo.steps':'Steps',
    'app.body.challenges.stepInfo.status':'Status',
    'app.body.challenges.stepInfo.toBlockchain':'To Blockchain',
    'app.body.challenges.stepInfo.stepHash':'Step Hash',
    'app.body.challenges.stepInfo.sendDate':'Sent Date',
    'app.body.challenges.stepInfo.time':'JST',
    'app.body.challenges.stepInfo.cleared':'Cleared',
    'app.body.challenges.mintAddress': 'Receiver address',
    'app.body.challenges.drap': 'Drag and drop some files here, or click to select files',
    'app.body.challenges.uploaded': 'Uploaded',
    'app.body.challenges.finish_import': 'Import challenge(s) is finished',
    'app.body.challenges.import_challenge': 'Import challenge',
    'app.body.challenges.final_transaction_hash': 'Challenge Final Transaction Hash',

    // Report
    'app.body.reports': 'Report',
    'app.body.reports.listReport': 'List report',
    'app.body.reports.reporter': 'Reporter',
    'app.body.reports.reported': 'Reported',
    'app.body.reports.reason': 'Reason',
    'app.body.reports.content': 'Content',
    'app.body.reports.status': 'Status',
    'app.body.reports.note': 'Note',
    'app.body.report.status.Open': 'Open',
    'app.body.report.status.Done': 'Done',
    'app.body.report.status.Processing': 'Processing',
    'app.body.reports.delete': ' Warning',
    'app.body.reports.notification': 'Do you want to delete report ID:',

    'app.body.reports.lastUpdate': 'Last Update',
    'app.body.reports.createdAt': 'Created At',

    // Transaction
    'app.body.transactions.history': 'Transaction history',
    'app.body.transactions.amount': 'Amount',
    'app.body.transactions.address': 'Address',
    'app.body.transactions.gas': 'Gas',
    'app.body.transactions.hash': 'Hash',
    'app.body.transactions.status': 'Status',
    'app.body.transactions.created_at': 'Create at',
    'app.body.transactions.from_address': 'From address',
    'app.body.transactions.time_tamp': 'Times tamp',

    // Wallet
    'app.body.wallets.id': 'Wallet ID',
    'app.body.wallets.wallet': 'Wallet',
    'app.body.wallets.user_name': 'User Name',
    'app.body.wallets.email': 'Email',
    'app.body.wallets.address': 'Wallet Address',
    'app.body.wallets.created_at': 'Created',
    'app.body.wallets.qr_code': 'QR Code',
    'app.body.wallets.detail': 'Detail',
    'app.body.wallets.user_id': 'User ID',
    'app.body.wallets.username': 'Username',
    'app.body.wallets.updated_at': 'Updated',
    'app.body.wallets.wallet_qr_code': 'Wallet QR code',

    // Networks
    'app.body.networks.id': 'Network ID',
    'app.body.networks.url': 'RPC Backend',
    'app.body.networks.url_cronjob': 'RPC Cro njob',
    'app.body.networks.name': 'Name',

    //Contract version
    'app.body.contractVersions.create': 'Create contract version',
    'app.body.contractVersions.detail': 'Contract version detail',
    'app.body.contractVersions.edit': 'Contract version edit',
    'app.body.contractVersions.id': 'ID',
    'app.body.contractVersions.user_id': 'User ID',
    'app.body.contractVersions.version': 'Version',
    'app.body.contractVersions.abi': 'ABI',
    'app.body.contractVersions.byte_code': 'File Byte code',
    'app.body.contractVersions.created_at': 'Created at',
    'app.body.contractVersions.updated_at': 'Update at',
    'app.body.contractVersions.delete': ' Warning',
    'app.body.contractVersions.notification': 'Do you want to delete contract version ID: ',
    'app.body.contractVersions.enter_version': 'Enter version',
    'app.body.contractVersions.enter_abi': 'Enter ABI',
    'app.body.contractVersions.file': 'Select file Byte code',
    'app.body.contractVersions.change_file': 'Update file Byte code',
    'app.body.contractVersions.dowload': 'Dowload file',

    // Table header
    'app.body.header.id': 'ID',
    'app.body.header.avatar': 'Avatar',
    'app.body.header.name': 'Name',
    'app.body.header.email': 'Email',
    'app.body.header.role': 'Role',
    'app.body.header.status': 'Status',
    'app.body.header.registedAt': 'Registered',
    'app.body.header.options': 'Options',
    'app.body.header.title': 'Title',
    'app.body.header.banner': 'Banner',
    'app.body.header.createdAt': 'Created at',
    'app.body.header.description': 'Description',
    'app.body.header.user': 'User',
    'app.body.header.platform': 'Platform',
    'app.body.header.version': 'Version',
    'app.body.header.environment': 'Environment',
    'app.body.header.reporter': 'Reporter',
    'app.body.header.reported': 'Reported',
    'app.body.header.reason': 'Reason',
    'app.body.header.content': 'Content',
    'app.body.header.note': 'Note',
    'app.body.header.filter': 'Filter',
    'app.body.header.detail': 'Detail',
    'app.body.header.notFound': 'Loading...',

    // Table row
    'app.body.id': 'ID',
    'app.body.avatar': 'Avatar',
    'app.body.options': 'Options',
    'app.body.row.user': 'プロフィール画像',
    'app.body.name': 'Name',
    'app.body.email': 'Email',
    'app.body.role': 'Role',
    'app.body.status': 'Status',
    'app.body.registedAt': 'Registed at',

    // Daily Step
    'app.body.dailyStep.challenger_id': 'Challenger ID',
    'app.body.dailyStep.challenger_username': 'Challenger UserName',
    'app.body.dailyStep.date': 'Date',
    'app.body.dailyStep.step': 'Steps',
    'app.body.dailyStep.time_send_step': 'Time of sending step data',
    'app.body.dailyStep.step_hash': 'Step Data Hash',
    'app.body.dailyStep.challenge_id': 'Challenge ID',
    'app.body.dailyStep.challenge_name': 'Challenge name',
    'app.body.dailyStep.challenge_hash': 'Challenge hash',
    'app.body.dailyStep.challenge_from': 'Challenge From',
    'app.body.dailyStep.challenge_to': 'Challenge To',

    // Status
    'app.body.status.all': 'All',

    // Environment
    'app.env.dev': 'development',
    'app.env.sta': 'staging',
    'app.env.pro': 'production',

    // Action
    'app.action.delete': 'Delete',
    'app.action.stop': 'Stop',
    'app.action.update': 'Update',
    'app.action.submit': 'Submit',
    'app.action.search': 'Search',
    'app.action.edit': 'Edit',
    'app.action.save': 'Save',
    'app.action.cancel': 'Cancel',
    'app.action.destroy_challenge': 'Destroy challenge',
    'app.action.close': 'Close',
    'app.action.add': 'Add',
    'app.action.create': 'Create',
    'app.action.pending': 'Pending',
    'app.action.mint.token': 'Mint Tanimo Token',
    'app.action.daily.step': 'Go to daily step',
    'app.action.export': 'Export CSV',
    'app.action.upload': 'Upload PrivateKey',
    'app.action.upload.metamask': 'Upload Metamask',
    'app.action.import': 'Import',

    // Message
    'app.message.banner.create_success': 'CREATE BANNER SUCCESS',
    'app.message.banner.delete_success': 'DELETE BANNER SUCCESS',
    'app.message.banner.update_success': 'UPDATE BANNER SUCCESS',

    'app.message.user.create_success': 'CREATE USER SUCCESS',
    'app.message.user.delete_success': 'DELETE USER SUCCESS',
    'app.message.user.update_success': 'UPDATE USER SUCCESS',

    'app.message.admin.create_success': 'CREATE USER SUCCESS',
    'app.message.admin.delete_success': 'DELETE USER SUCCESS',
    'app.message.admin.update_success': 'UPDATE USER SUCCESS',
    'app.message.admin.password_incorrect': 'OLD PASSWORD INCORRECT',
    'app.message.admin.password_validate': 'INVALID NEW PASSWORD',
    'app.message.admin.err_pass': 'Password length greater than or equal to 8!',
    'app.message.admin.err_confirm_pass': 'Confirm password error!',
    'app.message.admin.null':' ',

    'app.message.news.create_success': 'CREATE NEWS SUCCESS',
    'app.message.news.delete_success': 'DELETE NEWS SUCCESS',
    'app.message.news.update_success': 'UPDATE NEWS SUCCESS',

    'app.message.version.create_success': 'CREATE VERSION SUCCESS',
    'app.message.version.delete_success': 'DELETE VERSION SUCCESS',
    'app.message.version.update_success': 'UPDATE VERSION SUCCESS',
    'app.message.version.not_defined': 'Version not defined',

    'app.message.exercise.create_success': 'CREATE EXERCISE SUCCESS',
    'app.message.exercise.delete_success': 'DELETE EXERCISE SUCCESS',
    'app.message.exercise.update_success': 'UPDATE EXERCISE SUCCESS',

    'app.message.challenge.err': 'REQUEST DESTROY ERROR',
    'app.message.challenge.success': 'SEND DESTROY SUCCESS',
    'app.message.challenge.address_false': 'Address not Sponsor or Server',
    'app.message.challenge.address_invalid': 'Address invalid',
    'app.message.challenges.value_error': 'Invalid data',
    'app.message.challengeHash_exist': 'You cannot continue to destroy !',
    'app.message.challenges.null': ' ',
    'app.message.challenges.update_success': 'UPDATE CHALLENGE SUCCESS',

    'app.message.report.update_success': 'UPDATE REPORT SUCCESS',
    'app.message.report.delete_success': 'DELETE REPORT SUCCESS',

    'app.message.contractVersions.create_success': 'CREATE CONTRACT VERSION SUCCESS',
    'app.message.contractVersions.delete_success': 'DELETE CONTRACT VERSION SUCCESS',
    'app.message.contractVersions.update_success': 'UPDATE CONTRACT VERSION SUCCESS',
    'app.message.contractVersions.version_not_defined': 'Version not defined ',
    'app.message.mint.success': 'Mint coin success',
    'app.message.mint.error': 'Mint coin error',

    // Common
    'app.body.true': 'true',
    'app.body.false': 'false',
    'app.body.loadmore': 'Load more',
    'app.common.total': 'Total',

    // Pagination
    'app.pagination.pre': 'Pre',
    'app.pagination.next': 'Next',
  },
};
