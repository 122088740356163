import axios from '../../utils/axios';
import { LIMIT } from '../../utils/config';

const DailyStepService = {

  async getAllDailySteps(page = 1, limit = LIMIT) {
      try{
        const response = await axios.get('/challenges/all-daily-steps', {
          params: {
            page,
            limit,
          }
        })
        return response.data;
      } catch (e) {
        console.log(e)
        throw e;
      }
  },
  
}

export default DailyStepService;