import axios from '../../utils/axios';
import { LIMIT, STATUS, USER_ROLE } from '../../utils/config';

const AdminService = {

  async getListAdmins(status = STATUS.all, page = 1, limit = LIMIT, search='') {
    try {
      const response = await axios.get('/admin/users', {
        params: {
          page,
          limit,
          status,
          search,
          role: USER_ROLE.admin
        }
      })
      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  },

  async getById(id) {
    try {
      const response = await axios.get(`/users/${id}`);
      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  },

  async deleteById(id) {
    try {
      const response = await axios.delete(`/admin/users/${id}`);
      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  },

  async create(model) {
    try {
      const response = await axios.post('/admin/users', model);
      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  },

  /**
   * Update by id
   * @param {number} id id of user
   * @param {Object} model data to update
   */
  async updateById(id, model) {
    try {
      const response = await axios.put(`/admin/users/${id}`, model);
      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  }
}

export default AdminService;
