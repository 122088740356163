import axios from 'axios';
import { ENV } from './config';
import Storage from './storage';

const instance =  axios.create({
  baseURL: ENV.SERVER_API,
  headers: {
    'Content-Type': 'application/json',
    'platform': 'browser',
    'version': '1.0.0'
  }
});

// axios.defaults.baseURL = ENV.SERVER_API;
// axios.defaults.headers['Content-Type'] = 'application/json';
// axios.defaults.headers['platform'] = 'browser';
// axios.defaults.headers['version'] = '1.0.0';

/** Bind token */
instance.interceptors.request.use(function (config) {
  const token = Storage.getToken();
  if (token) {
    config.headers['Authorization'] = Storage.getToken()
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

export default instance;

