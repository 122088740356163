import ReportService from './ReportService';
import { toast } from 'react-toastify';
import { getMessageById } from '../Multilang/locales/index';

/** Get list report action */
export const GET_LIST_REPORT_SUCCESS = 'GET_LIST_REPORT_SUCCESS';
const getListReportSuccess = (result) => {
  return { type: GET_LIST_REPORT_SUCCESS, payload: result}
}

export const GET_LIST_REPORT_DOING = 'GET_LIST_REPORT_DOING';
const getListReportDoing = () => {
  return { type: GET_LIST_REPORT_DOING }
}

export const GET_LIST_REPORT_FAILURE = 'GET_LIST_REPORT_FAILURE';
const getListReportFail = (error) => {
  return { type: GET_LIST_REPORT_FAILURE, error }
}

/**
 * @param {number} page page number
 * @param {number} limit limit number
 */
export const getListReport = ( page, limit, search) => {
  return async dispatch => {

    dispatch(getListReportDoing());

    return ReportService.getListReport(page, limit, search)
      .then(response => {
        if (!response.error) {
          return dispatch(getListReportSuccess(response.data));
        }

        return dispatch(getListReportFail(response));
      })
  }
}

/** Delete by id */
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';
const deleteByIdSuccess = (result) => {
  toast.success(getMessageById('app.message.report.delete_success'));
  return { type: DELETE_REPORT_SUCCESS, payload: result }
}

export const DELETE_REPORT_DOING = 'DELETE_REPORT_DOING';
const deleteByIdDoing = () => {
  return { type: DELETE_REPORT_DOING }
}

export const DELETE_REPORT_FAILURE = 'DELETE_REPORT_FAILURE';
const deleteByIdFail = (error) => {
  toast.error(error.message);
  return { type: DELETE_REPORT_FAILURE, error }  
}

export const deleteById = (id) => {
  return async dispatch => {
    dispatch(deleteByIdDoing());

    return ReportService.deleteById(id)
      .then(response => {
        if (!response.error) {
          return dispatch(deleteByIdSuccess({ id }));
        }

        return dispatch(deleteByIdFail(response));
      })
  }
}

/** Update */
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS';
const updateByIdSuccess = (result) => {
  toast.success(getMessageById('app.message.report.update_success'));
  return { type: UPDATE_REPORT_SUCCESS, payload: result }
}

export const UPDATE_REPORT_DOING = 'UPDATE_REPORT_DOING';
const updateByIdDoing = () => {
  return { type: UPDATE_REPORT_DOING }
}

export const UPDATE_REPORT_FAILURE = 'UPDATE_REPORT_FAILURE';
const updateByIdFail = (error) => {
  toast.error(error.message);
  return { type: UPDATE_REPORT_FAILURE, error }  
}

export const updateById = (id, model) => {
  return async dispatch => {
    dispatch(updateByIdDoing());

    return ReportService.updateById(id, model)
      .then(response => {
        if (!response.error) {
          return dispatch(updateByIdSuccess(response.data));
        }

        return dispatch(updateByIdFail(response));
      })
  }
}

