import WalletService from './WalletService';

export const GET_LIST_WALLETS_SUCCESS = 'GET_LIST_WALLETS_SUCCESS';
const getListWalletSuccess = (result) => {
    return { type: GET_LIST_WALLETS_SUCCESS, payload: result }
}

export  const GET_LIST_WALLETS_DOING = 'GET_LIST_WALLETS_DOING';
const getListWalletDoing = (result) => {
    return { type: GET_LIST_WALLETS_DOING, payload: result }
}

export const GET_LIST_WALLETS_FAILURE = 'GET_LIST_WALLETS_FAILURE';
const getListWalletFail = (error) => {
    return { type: GET_LIST_WALLETS_FAILURE, error }
}

/**
 * @param {number} type type get wallet. 1: sponsor. 2: wallet
 * @param {number} page page number
 * @param {number} limit limit number
 */
export const getListWallets = (page, limit, search) => {
    return async dispatch => {

      dispatch(getListWalletDoing());

      return WalletService.getListWallets(page, limit, search)
      .then(response => {
        if (!response.error) {
            return dispatch(getListWalletSuccess(response.data));
        }

        return dispatch(getListWalletFail(response));
      })
    }
}

/** Get list transaction */
export const GET_LIST_TRANSACTION_SUCCESS = 'GET_LIST_TRANSACTION_SUCCESS';
const getListTransactionSuccess = (result) => {
  return { type: GET_LIST_TRANSACTION_SUCCESS, payload: result }  
}

export const GET_LIST_TRANSACTION_DOING = 'GET_LIST_TRANSACTION_DOING';
const getListTransactionDoing = () => {
  return { type: GET_LIST_TRANSACTION_DOING }
}

export const GET_LIST_TRANSACTION_FAILURE = 'GET_LIST_TRANSACTION_FAILURE';
const getListTransactionFail = (error) => {
  return { type: GET_LIST_TRANSACTION_FAILURE, error}
}

/**
 * @param {number} page page number
 * @param {number} limit page limit
 */
export const getListTransaction = (user_id, page, limit) => {
    return async dispatch => {
        dispatch(getListTransactionDoing());

        return WalletService.getTransactionbyAddress(user_id, page, limit)
          .then(response => {
            if(!response.error) {
                return dispatch(getListTransactionSuccess(response.data));
            } 

            return dispatch(getListTransactionFail(response));
          })
    }
}