import {
  GET_LIST_EXERCISE_PACKAGES_DOING,
  GET_LIST_EXERCISE_PACKAGES_FAILURE,
  GET_LIST_EXERCISE_PACKAGES_SUCCESS,

  DELETE_EXERCISE_PACKAGE_DOING,
  DELETE_EXERCISE_PACKAGE_FAILURE,
  DELETE_EXERCISE_PACKAGE_SUCCESS,

  CREATE_EXERCISE_PACKAGE_DOING,
  CREATE_EXERCISE_PACKAGE_FAILURE,
  CREATE_EXERCISE_PACKAGE_SUCCESS,

  UPDATE_EXERCISE_PACKAGE_DOING,
  UPDATE_EXERCISE_PACKAGE_FAILURE,
  UPDATE_EXERCISE_PACKAGE_SUCCESS
} from './ExercisePackageAction';

const initialState = {
  loading: false,
  error: '',
  exercisePackages: []
}

const exercisePackageReducer = (state = initialState, action = {}) => {
  switch(action.type) {
    case GET_LIST_EXERCISE_PACKAGES_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_LIST_EXERCISE_PACKAGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        exercisePackages: action.payload.results,
        total: action.payload.total
      }
    }

    case GET_LIST_EXERCISE_PACKAGES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case DELETE_EXERCISE_PACKAGE_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case DELETE_EXERCISE_PACKAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        exercisePackages: state.exercisePackages.filter(exercisePackage => exercisePackage.id !== action.payload.id)
      }
    }

    case DELETE_EXERCISE_PACKAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case CREATE_EXERCISE_PACKAGE_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case CREATE_EXERCISE_PACKAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        exercisePackages: [...state.exercisePackages, action.payload]
      }
    }

    case CREATE_EXERCISE_PACKAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case UPDATE_EXERCISE_PACKAGE_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case UPDATE_EXERCISE_PACKAGE_SUCCESS: {
      const exercisePackages = state.exercisePackages.map(exercisePackage => {
        if (exercisePackage.id === action.payload.id) {
          return action.payload;
        }

        return exercisePackage;
      });

      return {
        ...state,
        loading: false,
        exercisePackages
      }
    }

    case UPDATE_EXERCISE_PACKAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    default:
      return state;
  }
}

export default exercisePackageReducer;
