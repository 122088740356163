import {
  GET_LIST_BANNERS_DOING,
  GET_LIST_BANNERS_FAILURE,
  GET_LIST_BANNERS_SUCCESS,

  DELETE_BANNER_DOING,
  DELETE_BANNER_FAILURE,
  DELETE_BANNER_SUCCESS,

  CREATE_BANNER_DOING,
  CREATE_BANNER_FAILURE,
  CREATE_BANNER_SUCCESS,

  UPDATE_BANNER_DOING,
  UPDATE_BANNER_FAILURE,
  UPDATE_BANNER_SUCCESS
} from './BannerAction';

const initialState = {
  loading: false,
  error: '',
  banners: [],
  total: 0
}

const bannerReducer = (state = initialState, action = {}) => {
  switch(action.type) {
    case GET_LIST_BANNERS_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_LIST_BANNERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        banners: action.payload.results,
        total: action.payload.total
      }
    }

    case GET_LIST_BANNERS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case DELETE_BANNER_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case DELETE_BANNER_SUCCESS: {
      return {
        ...state,
        loading: false,
        banners: state.banners.filter(banner => banner.id !== action.payload.id),
        total: state.total - 1
      }
    }

    case DELETE_BANNER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case CREATE_BANNER_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case CREATE_BANNER_SUCCESS: {
      return {
        ...state,
        loading: false,
        banners: [...state.banners, action.payload],
        total: state.total + 1
      }
    }

    case CREATE_BANNER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case UPDATE_BANNER_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case UPDATE_BANNER_SUCCESS: {
      const banners = state.banners.map(banner => {
        if (banner.id === action.payload.id) {
          banner = {
            ...banner,
            ...action.payload
          }
        }

        return banner;
      });

      return {
        ...state,
        loading: false,
        banners
      }
    }

    case UPDATE_BANNER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    default:
      return state;
  }
}

export default bannerReducer;
