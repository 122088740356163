import {
  GET_STATUS_DEPLOY_DOING,
  GET_STATUS_DEPLOY_SUCCESS,
  GET_STATUS_DEPLOY_FAILURE
} from './DeployAction';

const initialState = {
  loading: false,
  error: '',
  is_locked: 0
}

const deployReducer = (state = initialState, action = {}) => {
switch(action.type) {
  case GET_STATUS_DEPLOY_DOING : {
    return {
      ...state,
      loading: true
    }
  }

  case GET_STATUS_DEPLOY_SUCCESS: {
    return {
      ...state,
      loading: false,
      is_locked: action.payload.is_locked
    }
  }

  case GET_STATUS_DEPLOY_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.error.message
    }
  }

  default:
    return state;
}
}

export default deployReducer;
