import axios from '../../utils/axios';

const DeployService =  {

  async getById() {
    try {
      const response = await axios.get(`/users/settings/get-deploy`);
      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  },

  /**
   * Update by id
   * @param {number} id challenge
   * @param {Object} model data to update
   */
  async updateById(data) {
    try {
      const response = await axios.post(`/users/settings/waiting-deploy`, data);
      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  },
}

export default DeployService
