import {
  GET_LIST_VERSION_DOING,
  GET_LIST_VERSION_FAILURE,
  GET_LIST_VERSION_SUCCESS,

  DELETE_VERSION_DOING,
  DELETE_VERSION_FAILURE,
  DELETE_VERSION_SUCCESS,

  CREATE_VERSION_DOING,
  CREATE_VERSION_FAILURE,
  CREATE_VERSION_SUCCESS,

  UPDATE_VERSION_DOING,
  UPDATE_VERSION_FAILURE,
  UPDATE_VERSION_SUCCESS
} from './VersionAction';

const initialState = {
  loading: false,
  error: '',
  version: [],
  total: 0
}

const versionReducer = (state = initialState, action = {}) => {
  switch(action.type) {
    case GET_LIST_VERSION_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_LIST_VERSION_SUCCESS: {
      return {
        ...state,
        loading: false,
        version: action.payload.results,
        total: action.payload.total
      }
    }

    case GET_LIST_VERSION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case DELETE_VERSION_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case DELETE_VERSION_SUCCESS: {
      return {
        ...state,
        loading: false,
        version: state.version.filter(version => version.id !== action.payload.id),
        total: state.total - 1
      }
    }

    case DELETE_VERSION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case CREATE_VERSION_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case CREATE_VERSION_SUCCESS: {
      return {
        ...state,
        loading: false,
        version: [...state.version, action.payload],
        total: state.total + 1
      }
    }

    case CREATE_VERSION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case UPDATE_VERSION_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case UPDATE_VERSION_SUCCESS: {
      const version = state.version.map(version => {
        if (version.id === action.payload.id) {
          version = {
            ...version,
            ...action.payload
          }
        }

        return version;
      });

      return {
        ...state,
        loading: false,
        version
      }
    }

    case UPDATE_VERSION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    default:
      return state;
  }
}

export default versionReducer;
