import AdminService from './AdminService';
import { toast } from 'react-toastify';
import { getMessageById } from '../Multilang/locales/index';
/** Get list users action */
export const GET_LIST_ADMINS_SUCCESS = 'GET_LIST_ADMINS_SUCCESS';
const getListAdminsSuccess = (result) => {
  return { type: GET_LIST_ADMINS_SUCCESS, payload: result}
}

export const GET_LIST_ADMINS_DOING = 'GET_LIST_ADMINS_DOING';
const getListAdminsDoing = () => {
  return { type: GET_LIST_ADMINS_DOING }
}

export const GET_LIST_ADMINS_FAILURE = 'GET_LIST_ADMINS_FAILURE';
const getListAdminsFail = (error) => {
  return { type: GET_LIST_ADMINS_FAILURE, error }
}

/**
 * @param {number} status status
 * @param {number} page page number
 * @param {number} limit limit number
 */
export const getListAdmins = (status, page, limit, search) => {
  return async dispatch => {

    dispatch(getListAdminsDoing());

    return AdminService.getListAdmins(status, page, limit, search)
      .then(response => {
        if (!response.error) {
          return dispatch(getListAdminsSuccess(response.data));
        }

        return dispatch(getListAdminsFail(response));
      })
  }
}

/** Delete by id */
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
const deleteByIdSuccess = (result) => {
  toast.success(getMessageById('app.message.admin.delete_success'));
  return { type: DELETE_ADMIN_SUCCESS, payload: result }
}

export const DELETE_ADMIN_DOING = 'DELETE_ADMIN_DOING';
const deleteByIdDoing = () => {
  return { type: DELETE_ADMIN_DOING }
}

export const DELETE_ADMIN_FAILURE = 'DELETE_ADMIN_FAILURE';
const deleteByIdFail = (error) => {
  toast.error(error.message);
  return { type: DELETE_ADMIN_FAILURE, error }  
}

export const deleteById = (id) => {
  return async dispatch => {
    dispatch(deleteByIdDoing());

    return AdminService.deleteById(id)
      .then(response => {
        if (!response.error) {
          return dispatch(deleteByIdSuccess({ id }));
        }

        return dispatch(deleteByIdFail(response));
      })
  }
}

/** Create */
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
const createSuccess = (result) => {
  toast.success(getMessageById('app.message.admin.create_success'));
  return { type: CREATE_ADMIN_SUCCESS, payload: result }
}

export const CREATE_ADMIN_DOING = 'CREATE_ADMIN_DOING';
const createDoing = () => {
  return { type: CREATE_ADMIN_DOING }
}

export const CREATE_ADMIN_FAILURE = 'CREATE_ADMIN_FAILURE';
const createFail = (error) => {
  toast.error(error.message);
  return { type: CREATE_ADMIN_FAILURE, error }  
}

export const create = (model) => {
  return async dispatch => {
    dispatch(createDoing());

    return AdminService.create(model)
      .then(response => {
        if (!response.error) {
          return dispatch(createSuccess(response.data));
        }

        return dispatch(createFail(response));
      })
  }
}

/** Update */
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
const updateByIdSuccess = (result) => {
  toast.success(getMessageById('app.message.admin.update_success'));
  return { type: UPDATE_ADMIN_SUCCESS, payload: result }
}

export const UPDATE_ADMIN_DOING = 'UPDATE_ADMIN_DOING';
const updateByIdDoing = () => {
  return { type: UPDATE_ADMIN_DOING }
}

export const UPDATE_ADMIN_FAILURE = 'UPDATE_ADMIN_FAILURE';
const updateByIdFail = (error) => {
  toast.error(error.message);
  return { type: UPDATE_ADMIN_FAILURE, error }  
}

export const updateById = (id, model) => {
  return async dispatch => {
    dispatch(updateByIdDoing());

    return AdminService.updateById(id, model)
      .then(response => {
        if (!response.error) {
          return dispatch(updateByIdSuccess(response.data));
        }

        return dispatch(updateByIdFail(response));
      })
  }
}

