import UserService from './UserService';
import { toast } from 'react-toastify';
import { getMessageById } from '../Multilang/locales/index';
/** Get list users action */
export const GET_LIST_USERS_SUCCESS = 'GET_LIST_USERS_SUCCESS';
const getListUsersSuccess = (result) => {
  return { type: GET_LIST_USERS_SUCCESS, payload: result}
}

export const GET_LIST_USERS_DOING = 'GET_LIST_USERS_DOING';
const getListUsersDoing = () => {
  return { type: GET_LIST_USERS_DOING }
}

export const GET_LIST_USERS_FAILURE = 'GET_LIST_USERS_FAILURE';
const getListUsersFail = (error) => {
  return { type: GET_LIST_USERS_FAILURE, error }
}

/**
 * @param {number} status status
 * @param {number} page page number
 * @param {number} limit limit number
 */
export const getListUsers = (status, isReceived, page, limit, search) => {
  return async dispatch => {

    dispatch(getListUsersDoing());

    return UserService.getListUsers(status, isReceived, page, limit, search)
      .then(response => {
        if (!response.error) {
          return dispatch(getListUsersSuccess(response.data));
        }

        return dispatch(getListUsersFail(response));
      })
  }
}

/** Delete by id */
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
const deleteByIdSuccess = (result) => {
  toast.success(getMessageById('app.message.user.delete_success'));
  return { type: DELETE_USER_SUCCESS, payload: result }
}

export const DELETE_USER_DOING = 'DELETE_USER_DOING';
const deleteByIdDoing = () => {
  return { type: DELETE_USER_DOING }
}

export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
const deleteByIdFail = (error) => {
  toast.error(error.message);
  return { type: DELETE_USER_FAILURE, error }
}

export const deleteById = (id) => {
  return async dispatch => {
    dispatch(deleteByIdDoing());

    return UserService.deleteById(id)
      .then(response => {
        if (!response.error) {
          return dispatch(deleteByIdSuccess({ id }));
        }

        return dispatch(deleteByIdFail(response));
      })
  }
}

/** Create */
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
const createSuccess = (result) => {
  toast.success(getMessageById('app.message.user.create_success'));
  return { type: CREATE_USER_SUCCESS, payload: result }
}

export const CREATE_USER_DOING = 'CREATE_USER_DOING';
const createDoing = () => {
  return { type: CREATE_USER_DOING }
}

export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
const createFail = (error) => {
  toast.error(error.message);
  return { type: CREATE_USER_FAILURE, error }
}

export const create = (model) => {
  return async dispatch => {
    dispatch(createDoing());

    return UserService.create(model)
      .then(response => {
        if (!response.error) {
          return dispatch(createSuccess(response.data));
        }

        return dispatch(createFail(response));
      })
  }
}

/** Update */
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
const updateByIdSuccess = (result) => {
  toast.success(getMessageById('app.message.user.update_success'));
  return { type: UPDATE_USER_SUCCESS, payload: result }
}

export const UPDATE_USER_DOING = 'UPDATE_USER_DOING';
const updateByIdDoing = () => {
  return { type: UPDATE_USER_DOING }
}

export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
const updateByIdFail = (error) => {
  toast.error(error.message);
  return { type: UPDATE_USER_FAILURE, error }
}

export const updateById = (id, model) => {
  return async dispatch => {
    dispatch(updateByIdDoing());

    return UserService.updateById(id, model)
      .then(response => {
        if (!response.error) {
          return dispatch(updateByIdSuccess(response.data));
        }

        return dispatch(updateByIdFail(response));
      })
  }
}

