import Storage from '../../utils/storage';
import AuthService from './AuthService';
import { toast } from 'react-toastify';

/** Authentication action */
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
const authenticateSuccess = (result) => {
  return { type: AUTHENTICATE_SUCCESS, payload: result }
}

export const AUTHENTICATE_FAILURE = 'AUTHENTICATE_FAILURE';
const authenticateFail = (error) => {
  if (['INVALID_TOKEN', 'TOKEN_EXPIRED_ERROR'].includes(error.message)) {
    Storage.deleteToken();
  }

  return { type: AUTHENTICATE_FAILURE, error }
}

export const authenticate = () => {
  return async dispatch => {

    const token = Storage.getToken();
    if (!token) {
      return dispatch(authenticateFail({
        message: 'NOT_AUTHENTICATED_ERROR'
      }));
    }

    return AuthService.getCurrentUserInfo()
      .then(response => {
        if (!response.error) {
          return dispatch(authenticateSuccess(response.data));
        }

        return dispatch(authenticateFail(response));
      })
  }
}

/** Login action */
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const loginSuccess = (result) => {
  /** Save token */
  Storage.setToken(result.token)
  return { type: LOGIN_SUCCESS, payload: result }
}

export const LOGIN_FAILURE = 'LOGIN_FAILURE';
const loginFail = (error) => {
  toast.error(error.message);
  return { type: LOGIN_FAILURE, error }
}

export const LOGIN_DOING = 'LOGIN_DOING';
const loginDoing = () => {
  return { type: LOGIN_DOING }
}

export const login = ({ email, password }) => {
  return async dispatch => {

    dispatch(loginDoing());

    return AuthService.login2fa({ email, password })
      .then(response => {
        if (!response.error) {
          return dispatch(verifySuccess(response.data.token));
        }

        return dispatch(loginFail(response));
      })
  }
}

export const verify = ({ code, token }) => {
  return async dispatch => {

    dispatch(loginDoing());

    return AuthService.verifyLogin2fa({ code, token })
      .then(response => {
        if (!response.error) {
          return dispatch(loginSuccess(response.data));
        }

        return dispatch(verifyTokenFail(response));
      })
  }
}

/** Logout action */
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const logout = () => {
  Storage.clear();
  return { type: LOGOUT_SUCCESS }
}

export const VERIFY_TOKEN_SUCCESS = "VERIFY_TOKEN_SUCCESS";
const verifySuccess = (result) => {
  Storage.setToken(result)
  return { type: VERIFY_TOKEN_SUCCESS, payload: result }
}

export const VERIFY_TOKEN_FAILURE = "VERIFY_TOKEN_FAILURE";

const verifyTokenFail = (error) => {
  toast.error(error.message);
  return { type: VERIFY_TOKEN_FAILURE, error }
}

/* Forget Password Super Admin */
export const SEND_EMAIL_FORGET_SUCCESS = "SEND_EMAIL_FORGET_SUCCESS";
const sendEmailSuccess = (result) => {
  return { type: SEND_EMAIL_FORGET_SUCCESS, payload: result }
}

export const SEND_EMAIL_FORGET_FAILURE = "SEND_EMAIL_FORGET_FAILURE";
const sendEmailFail = (error) => {
  toast.error(error.message);
  return { type: SEND_EMAIL_FORGET_FAILURE, error }
}

export const sendEmailForget = ({ email }) => {
  return async dispatch => {
    return AuthService.sendEmailForget({ email })
      .then(response => {
        if (!response.error) {
          return dispatch(sendEmailSuccess(response.data.token));
        }

        return dispatch(sendEmailFail(response));
      })
  }
}

export const VERIFY_CODE_FORGET_PASSWORD_SUCCESS = "VERIFY_CODE_FORGET_PASSWORD_SUCCESS";
const verifyCodeForgetPasswordSuccess = (result) => {
  return { type: VERIFY_CODE_FORGET_PASSWORD_SUCCESS, payload: result }
}

export const VERIFY_CODE_FORGET_PASSWORD_FAILURE = "VERIFY_CODE_FORGET_PASSWORD_FAILURE";
const verifyCodeForgetPasswordFail = (error) => {
  toast.error(error.message);
  return { type: VERIFY_CODE_FORGET_PASSWORD_FAILURE, error }
}

export const verifyCodeForgetPassword = ({ code, token }) => {
  return async dispatch => {

    return AuthService.verifyCodeForgetPassword({ code, token })
      .then(response => {
        if (!response.error) {
          return dispatch(verifyCodeForgetPasswordSuccess(response.data));
        }

        return dispatch(verifyCodeForgetPasswordFail(response));
      })
  }
}

export const UPDATE_PASSWORD_SA_SUCCESS = "UPDATE_PASSWORD_SA_SUCCESS";
const updatePassworSASuccess = (result) => {
  return { type: UPDATE_PASSWORD_SA_SUCCESS, payload: result }
}

export const UPDATE_PASSWORD_SA_FAILURE = "UPDATE_PASSWORD_SA_FAILURE";
const updatePassworSAFail = (error) => {
  toast.error(error.message);
  return { type: UPDATE_PASSWORD_SA_FAILURE, error }
}

export const updatePasswordSA = (model) => {
  return async dispatch => {

    return AuthService.updatePasswordSA(model)
      .then(response => {
        if (!response.error) {
          return dispatch(updatePassworSASuccess(response.data));
        }

        return dispatch(updatePassworSAFail(response));
      })
  }
}

