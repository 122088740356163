import { combineReducers } from 'redux';
import authReducer from '../components/Auth/AuthReducer';
import userReducer from '../components/Users/UserReducer';
import exercisePackageReducer from '../components/ExercisePackages/ExercisePackageReducer';
import bannerReducer from '../components/Banners/BannerReducer';
import newsReducer from '../components/News/NewsReducer';
import versionReducer from '../components/Versions/VersionReducer';
import challengeReducer from '../components/Challenges/ChallengeReducer';
import deployReducer from '../components/Deploy/DeployReducer';
import reportReducer from '../components/Reports/ReportReducer';
import walletReducer from '../components/Wallets/WalletReducer';
import networksReducer from '../components/Networks/NetworksReducer';
import localeReducer from '../components/Multilang/LocaleReducer';
import adminReducer from '../components/Admins/AdminReducer';
import contractVersionReducer from '../components/ContractVersions/ContractVersionReducer';
import tanimotoTokenReducer from '../components/TanimotoToken/TanimotoTokenReducer';
import dailyStepReducer from '../components/DailySteps/DailyStepReducer';

const rootReducer = combineReducers({
  authReducer,
  adminReducer,
  userReducer,
  exercisePackageReducer,
  bannerReducer,
  newsReducer,
  challengeReducer,
  versionReducer,
  reportReducer,
  walletReducer,
  networksReducer,
  localeReducer,
  contractVersionReducer,
  tanimotoTokenReducer,
  dailyStepReducer,
  deployReducer,
});

export default rootReducer;

