import BannerService from './BannerService';
import S3Service from '../S3/S3Service';
import { toast } from 'react-toastify';
import { TYPE_CREATE_BANNER } from '../../utils/config';
import {getMessageById} from '../Multilang/locales';

/** Get list banners action */
export const GET_LIST_BANNERS_SUCCESS = 'GET_LIST_BANNERS_SUCCESS';
const getListBannersSuccess = (result) => {
  return { type: GET_LIST_BANNERS_SUCCESS, payload: result }
}

export const GET_LIST_BANNERS_DOING = 'GET_LIST_BANNERS_DOING';
const getListBannersDoing = () => {
  return { type: GET_LIST_BANNERS_DOING }
}

export const GET_LIST_BANNERS_FAILURE = 'GET_LIST_BANNERS_FAILURE';
const getListBannersFail = (error) => {
  return { type: GET_LIST_BANNERS_FAILURE, error }
}

/**
 * @param {number} page page number
 * @param {number} limit limit number
 */
export const getListBanners = (page, limit, search) => {
  return async dispatch => {

    dispatch(getListBannersDoing());

    return BannerService.getListBanners(page, limit, search)
      .then(response => {
        if (!response.error) {
          return dispatch(getListBannersSuccess(response.data));
        }

        return dispatch(getListBannersFail(response));
      })
  }
}

/** Delete by id */
export const DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS';
const deleteByIdSuccess = (result) => {
  toast.success(getMessageById('app.message.banner.delete_success'));
  return { type: DELETE_BANNER_SUCCESS, payload: result }
}

export const DELETE_BANNER_DOING = 'DELETE_BANNER_DOING';
const deleteByIdDoing = () => {
  return { type: DELETE_BANNER_DOING }
}

export const DELETE_BANNER_FAILURE = 'DELETE_BANNER_FAILURE';
const deleteByIdFail = (error) => {
  toast.error(error.message);
  return { type: DELETE_BANNER_FAILURE, error }
}

export const deleteById = (id) => {
  return async dispatch => {
    dispatch(deleteByIdDoing());

    return BannerService.deleteById(id)
      .then(response => {
        if (!response.error) {
          return dispatch(deleteByIdSuccess({ id }));
        }

        return dispatch(deleteByIdFail(response));
      })
  }
}

/** Create */
export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS';
const createSuccess = (result) => {
  toast.success(getMessageById('app.message.banner.create_success'));
  return { type: CREATE_BANNER_SUCCESS, payload: result }
}

export const CREATE_BANNER_DOING = 'CREATE_BANNER_DOING';
const createDoing = () => {
  return { type: CREATE_BANNER_DOING }
}

export const CREATE_BANNER_FAILURE = 'CREATE_BANNER_FAILURE';
const createFail = (error) => {
  toast.error(error.message);
  return { type: CREATE_BANNER_FAILURE, error }
}

export const create = ({ imageFile, typeCreateBanner, ...model }) => {
  return async dispatch => {
    dispatch(createDoing());

    /** Upload image when create banner using file */
    if (typeCreateBanner === TYPE_CREATE_BANNER.file) {
      const response = await S3Service.uploadToS3(imageFile);

      if (!response.error) {
        model.banner = response.data.endpoint;
      } else {
        return dispatch(createFail(response));
      }
    }

    return BannerService.create(model)
      .then(response => {
        if (!response.error) {
          return dispatch(createSuccess(response.data));
        }

        return dispatch(createFail(response));
      })
  }
}

/** Update */
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS';
const updateByIdSuccess = (result) => {
  toast.success(getMessageById('app.message.banner.update_success'));
  return { type: UPDATE_BANNER_SUCCESS, payload: result }
}

export const UPDATE_BANNER_DOING = 'UPDATE_BANNER_DOING';
const updateByIdDoing = () => {
  return { type: UPDATE_BANNER_DOING }
}

export const UPDATE_BANNER_FAILURE = 'UPDATE_BANNER_FAILURE';
const updateByIdFail = (error) => {
  toast.error(error.message);
  return { type: UPDATE_BANNER_FAILURE, error }
}

export const updateById = (id, model) => {
  return async dispatch => {
    dispatch(updateByIdDoing());

    return BannerService.updateById(id, model)
      .then(response => {
        if (!response.error) {
          return dispatch(updateByIdSuccess(response.data));
        }

        return dispatch(updateByIdFail(response));
      })
  }
}

