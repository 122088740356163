import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';
// import en from 'react-intl/locale-data/en';
// import ja from 'react-intl/locale-data/ja';
// import { addLocaleData } from 'react-intl';
import ConnectIntProvider from './ConnectIntProvider';

// addLocaleData([...en, ...ja]);

const Main = () => {
  return (
    <Provider store={store}>
      <ConnectIntProvider>
        <App />
      </ConnectIntProvider>
    </Provider>
  );
}

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
