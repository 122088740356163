import {
  GET_LIST_CONTRACTVERSIONS_DOING,
  GET_LIST_CONTRACTVERSIONS_FAILURE,
  GET_LIST_CONTRACTVERSIONS_SUCCESS,

  DELETE_CONTRACTVERSION_DOING,
  DELETE_CONTRACTVERSION_FAILURE,
  DELETE_CONTRACTVERSION_SUCCESS,

  CREATE_CONTRACTVERSION_DOING,
  CREATE_CONTRACTVERSION_FAILURE,
  CREATE_CONTRACTVERSION_SUCCESS,

  UPDATE_CONTRACTVERSION_DOING,
  UPDATE_CONTRACTVERSION_FAILURE,
  UPDATE_CONTRACTVERSION_SUCCESS
} from './ContractVersionAction';

const initialState = {
  loading: false,
  error: '',
  contractVersions: [],
  total: 0
}

const contractVersionReducer = (state = initialState, action = {}) => {
  switch(action.type) {
    case GET_LIST_CONTRACTVERSIONS_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_LIST_CONTRACTVERSIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        contractVersions: action.payload.results,
        total: action.payload.total
      }
    }

    case GET_LIST_CONTRACTVERSIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case DELETE_CONTRACTVERSION_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case DELETE_CONTRACTVERSION_SUCCESS: {
      return {
        ...state,
        loading: false,
        contractVersions: state.contractVersions.filter(contractVersion => contractVersion.id !== action.payload.id),
        total: state.total - 1
      }
    }

    case DELETE_CONTRACTVERSION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case CREATE_CONTRACTVERSION_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case CREATE_CONTRACTVERSION_SUCCESS: {
      return {
        ...state,
        loading: false,
        contractVersions: [...state.contractVersions, action.payload],
        total: state.total + 1
      }
    }

    case CREATE_CONTRACTVERSION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case UPDATE_CONTRACTVERSION_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case UPDATE_CONTRACTVERSION_SUCCESS: {
      const contractVersions = state.contractVersions.map(contractVersion => {
        if (contractVersion.id === action.payload.id) {
          contractVersion = {
            ...contractVersion,
            ...action.payload
          }
        }

        return contractVersion;
      });

      return {
        ...state,
        loading: false,
        contractVersions
      }
    }

    case UPDATE_CONTRACTVERSION_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    default:
      return state;
  }
}

export default contractVersionReducer;
