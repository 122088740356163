import axios from '../../utils/axios';
import { LIMIT } from '../../utils/config';

const VersionService = {

  async getListVersions(page = 1, limit = LIMIT, search = '') {
    try {
      const response = await axios.get(`/admin/versions?page=${page}&limit=${limit}&search=${search}`);

      return response.data;
    } catch (e) {
      console.log(e);     
      throw e;
    }
  },

  async getById(id) {
    try {
      const response = await axios.get(`/admin/versions/${id}`);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async deleteById(id) {
    try {
      const response = await axios.delete(`/admin/versions/${id}`);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async create(model) {
    try {
      const response = await axios.post('/admin/versions', model);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Update by id
   * @param {number} id id of user
   * @param {Object} model data to update
   */
  async updateById(id, model) {
    try {
      const response = await axios.put(`/admin/versions/${id}`, model);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
}

export default VersionService;
