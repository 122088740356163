import { LANG } from '../../../utils/config';

export const JAPAN = {
  lang: LANG.ja,
  messages: {
    // Header
    'app.header.lang': 'ja',
    'app.header.hello': 'こんにちは！',
    'app.header.setting': '設定',
    'app.header.logout': 'ログアウト',

    // Sidebar
    'app.sidebar.home': 'ホーム',
    'app.sidebar.users': 'ユーザー',
    'app.sidebar.admins': '運営者',
    'app.sidebar.exercisePackages': 'エクササイズパッケージ',
    'app.sidebar.banners': 'バナー',
    'app.sidebar.news': 'ニュース',
    'app.sidebar.versions': 'バージョン',
    'app.sidebar.challenges': 'チャレンジ',
    'app.sidebar.reports': 'レポート',
    'app.sidebar.wallets': 'ウォレット',
    'app.sidebar.contractVersions': '契約バージョン',
    'app.sidebar.deploy': '配備する',
    'app.sidebar.listPrompt': 'リストプロンプトを取得',
    'app.sidebar.recommendation': 'おすすめ',
    'app.sidebar.cheer': '応援 & モチベーション',
    'app.sidebar.question': '質問',
    'app.sidebar.newPrompt': '新しいプロンプトを追加',
    'app.sidebar.tanimoto.token': 'TanimoToken',
    'app.sidebar.dailyStep': 'Daily Steps',
    'app.sidebar.networks': 'Networks',

    //Admin
    'app.body.admins.createAdmin': '管理者作成',
    'app.body.admins.adminDetail': '詳しい管理者',
    'app.body.admins.adminEdit': '管理者編集',

    // User
    'app.body.users.id': 'ユーザーID',
    'app.body.users.createUser': 'ユーザー作成',
    'app.body.users.userDetail': 'ユーザー詳細',
    'app.body.users.userEdit': 'ユーザー編集',
    'app.body.users.updateUser': 'ユーザー更新',
    'app.body.users.role': '役割',
    'app.body.users.role.user': 'ユーザー',
    'app.body.users.role.admin': '運営者',
    'app.body.users.role.superAdmin': 'スーパー管理者',
    'app.body.users.status.active': 'アクティブ',
    'app.body.users.status.inactive': '非アクティブ',
    'app.body.users.status.block': 'ロック',
    'app.body.users.status.all': '全て',
    'app.body.users.userName': 'ユーザー名',
    'app.body.users.userWalllet': 'ウォレットアドレス',
    'app.body.users.email': 'E-mail',
    'app.body.users.qr_code': 'QRコード',
    'app.body.users.device': 'デバイス',
    'app.body.users.phoneNumber': '電話番号',
    'app.body.users.enterPhoneNumber': '電話番号入力',
    'app.body.users.enterUserName': 'ユーザー名入力',
    'app.body.users.enterEmail': 'E-mail入力',
    'app.body.users.password': 'パスワード',
    'app.body.users.confirmPass': 'パスワード確認',
    'app.body.users.enterPassword': 'パスワード入力',
    'app.body.users.showPassword': 'パスワード表示',
    'app.body.users.errPass': 'パスワード間違い',
    'app.body.users.errPhone': '不明な電話番号',
    'app.body.users.register_step.registerEmail': 'E-mail登録',
    'app.body.users.register_step.enterPinCode': 'PIN入力',
    'app.body.users.register_step.agreeTermOfUse': '利用規約同意',
    'app.body.users.register_step.configTFA': '2段階認証設定',
    'app.body.users.register_step.finish': '終了',
    'app.body.users.is_update_password.false': '偽',
    'app.body.users.is_update_password.true': '真',
    'app.body.users.new_pass': '新しいパスワード',
    'app.body.users.confirm_pass': 'パスワードを認証する',

    // config follow database
    'app.body.users.username': 'ユーザー名',
    'app.body.users.birthday': '誕生日',
    'app.body.users.height': '身長',
    'app.body.users.weight': '体重',
    'app.body.users.phone_number': '電話番号',
    'app.body.users.status': 'ステータス',
    'app.body.users.avatar': 'アバター',
    'app.body.users.register_step': '登録ステップ',
    'app.body.users.is_update_password': 'パスワード更新',
    'app.body.users.is_deploying': 'アプリをデプロイ中です',
    'app.body.users.created_at': '作成',
    'app.body.users.updated_at': 'アップデート',
    'app.body.users.userSetting': 'ユーザー設定',
    'app.body.users.statusFilter': 'ステータスフィルター',
    'app.body.users.receivedNewsFilter': '受信したニュース',
    'app.body.users.delete': ' 警告',
    'app.body.users.notification': 'ユーザーIDを削除しますか？',

    // Exercise packages
    'app.body.exercise.createExercise': 'エクササイズパッケージ作成',
    'app.body.exercise.exerciseDetail': 'エクササイズパッケージ詳細',
    'app.body.exercise.exerciseEdit': 'エクササイズパッケージ編集',
    'app.body.exercise.updateWork': '業務更新',
    'app.body.exercise.id': 'パッケージ ID',
    'app.body.exercise.name': 'パッケージ名',
    'app.body.exercise.description': '説明',
    'app.body.exercise.amount': '供託金額 (ETH)',
    'app.body.exercise.execute_time': 'チャレンジ可能日数 (日)',
    'app.body.exercise.type_challenge': '運動の種類',
    'app.body.exercise.daily_min_step_require': '目標歩数 (1日)',
    'app.body.exercise.min_day_require': '最低目標達成日数 (日)',
    'app.body.exercise.gas_fee': 'チャレンジャーへのGAS代 (ETH)',
    'app.body.exercise.administrator_fee': '管理者料金 (ETH)',
    'app.body.exercise.sponsor': 'スポンサー',
    'app.body.exercise.challenger': 'チャレンジャー',
    'app.body.exercise.fee_success_percentage': '成功時サービス手数料(%)',
    'app.body.exercise.fee_success': '成功時サービス手数料',
    'app.body.exercise.fee_error_percentage': '失敗時サービス手数料(%)',
    'app.body.exercise.fee_error': '失敗時サービス手数料',
    'app.body.exercise.give_up': 'ギブアップ',
    'app.body.exercise.give_up_pattern': 'ギブアップパターン',
    'app.body.exercise.created_at': '作成',
    'app.body.exercise.options': 'オプション',
    'app.body.exercise.is_setting_give_up': 'ギブアップ設定',
    'app.body.exercise.enterName': '名前入力',
    'app.body.exercise.enterDescription': '説明入力',
    'app.body.exercise.defaultType': '歩行',
    'app.body.exercise.updated_at': 'パッケージ更新日時',
    'app.body.exercise.date_start': '開始日',
    'app.body.exercise.date_end': '終了日',
    'app.body.exercise.create_at': 'パッケージ作成日時',
    'app.body.exercise.err_amount': '金額が正しくありません',
    'app.body.exercise.err_gas_free': 'ガス代が正しくありません',
    'app.body.exercise.err_administrator_fee': '管理者料金は正しくありません',
    'app.body.exercise.err_exercise_time': 'エクササイズ期間が正しくありません',
    'app.body.exercise.err_min_day_require': '最低目標達成日数が正しくありません',
    'app.body.exercise.err_daily_min_step_require': '目標歩数が正しくありません',
    'app.body.exercise.err_total_fee': 'フィー合計が正しくありません',
    'app.body.exercise.delete': ' 警告',
    'app.body.exercise.notification': 'エクササイズパッケージIDを削除しますか？ ID:',

    // Banners
    'app.body.banners': 'バナー',
    'app.body.banners.id': 'ID',
    'app.body.banners.createBanner': 'バナー作成',
    'app.body.banners.bannerDetail': '詳しいバナー',
    'app.body.banners.bannerEdit': 'バナー編集',
    'app.body.banners.title': 'タイトル',
    'app.body.banners.enter_title': 'タイトル入力',
    'app.body.banners.banner': 'バナー',
    'app.body.banners.status': 'ステータス',
    'app.body.banners.status.disabled': '不可',
    'app.body.banners.status.enabled': '可',
    'app.body.banners.updated_at': 'アップデート',
    'app.body.banners.description': '説明',
    'app.body.banners.image_url': 'URLイメージ',
    'app.body.banners.created_at': 'で作成',
    'app.body.banners.options': 'オプション',
    'app.body.banners.create_type': 'タイプ作成',
    'app.body.banners.delete': ' 警告',
    'app.body.banners.notification': 'バナーを削除したい？ ID:',

    // News
    'app.body.news.listNews': 'ニュースリスト',
    'app.body.news.createNews': 'ニュース作成',
    'app.body.news.newDetail': 'ニュース詳細',
    'app.body.news.id': 'ID',
    'app.body.news.name': '名前',
    'app.body.news.title': 'タイトル',
    'app.body.news.enter_title': 'タイトル入力',
    'app.body.news.status': 'ステータス',
    'app.body.news.status.disabled': '不可',
    'app.body.news.status.enabled': '可',
    'app.body.news.type.showAll': 'すべて表示する',
    'app.body.news.type.showNormal': '通常を表示',
    'app.body.news.url': 'URLニュース',
    'app.body.news.enter_url': 'URLニュース入力',
    'app.body.news.banner': 'バナー',
    'app.body.news.created_at': '作成',
    'app.body.news.updated_at': 'アップデート',
    'app.body.news.user_id': 'ユーザーID',
    'app.body.news.enterNewsName': 'ニュース名入力',
    'app.body.news.delete': ' 警告',
    'app.body.news.notification': 'ニュースIDを削除しますか？ ID:',
    'app.body.news.newEdit': 'ニュース編集',

    // Versions
    'app.body.versions.versionCreate': 'バージョン作成',
    'app.body.versions.versionDetail': 'バージョン詳細',
    'app.body.versions.versionEdit': 'バージョン編集',
    'app.body.versions.name': '名前',
    'app.body.versions.banner': 'バナー',
    'app.body.versions.id': 'ID',
    'app.body.versions.version': 'バージョン',
    'app.body.versions.platform': 'プラットフォーム',
    'app.body.versions.environment': '環境',
    'app.body.versions.type': 'タイプ',
    'app.body.versions.is_default': 'デフォルト',
    'app.body.versions.created_at': '作成',
    'app.body.versions.updated_at': 'アップデート',
    'app.body.environment.1': '開発',
    'app.body.environment.2': 'ステージ',
    'app.body.environment.3': 'プロダクト',
    'app.body.versions.type.0': 'オプション',
    'app.body.versions.type.1': '強制アップデート',
    'app.body.versions.delete': ' 警告',
    'app.body.versions.notification': 'バージョンIDを削除しますか？ ID:',

    // Challenges
    'app.body.challenges.challengeDetail': 'チャレンジ詳細',
    'app.body.challenges.id': 'チャレンジID',
    'app.body.challenges.name_challenge': 'チャレンジ名',
    'app.body.challenges.status': '状況',
    'app.body.challenges.amount': '供託金額',
    'app.body.challenges.execute_time': 'チャレンジ可能日数 (日)',
    'app.body.challenges.type_challenge': 'チャレンジ作成日時',
    'app.body.challenges.type_of_exercise': '運動の種類',
    'app.body.challenges.sponsor': 'スポンサー',
    'app.body.challenges.challenger_name': 'チャレンジャー',
    'app.body.challenges.period_from': 'チャレンジ可能期間 開始日',
    'app.body.challenges.period_to': 'チャレンジ可能期間 終了日',
    'app.body.challenges.hash': 'ハッシュ',
    'app.body.challenges.address': 'アドレス',
    'app.body.challenges.contract_address': 'コントラクトアドレス',
    'app.body.challenges.network': 'ネットワーク',
    'app.body.challenges.give_up_hash': 'ギブアップハッシュ',
    'app.body.challenges.close_challenge_hash': 'チャレンジ終了ハッシュ',
    'app.body.challenges.destroy_hash': 'ハッシュ破棄',
    'app.body.challenges.signature_data': 'シグネチャーデータ',
    'app.body.challenges.sponsor_email': 'スポンサーE-mail',
    'app.body.challenges.sponsor_username': 'スポンサーユーザー名',
    'app.body.challenges.sponsor_wallet_address': 'ウォレットアドレス(スポンサー）',
    'app.body.challenges.sponsor_birthday': 'スポンサー誕生日',
    'app.body.challenges.sponsor_phone_number': 'スポンサー電話番号',
    'app.body.challenges.register_step': '登録ステップ',
    'app.body.challenges.challenger_email': 'チャレンジャーE-mail',
    'app.body.challenges.challenge_username': 'チャレンジャーユーザー名',
    'app.body.challenges.challenger_birthday': 'チャレンジャー誕生日',
    'app.body.challenges.challenge_wallet_address': 'ウォレットアドレス(チャレンジャー）',
    'app.body.challenges.challenger_phone_number': 'チャレンジャー電話番号',
    'app.body.challenges.gas_limit': 'ガスリミット',
    'app.body.challenges.low': '低',
    'app.body.challenges.hight': '高',
    'app.body.challenges.transaction_fee': 'トランザクションフィー',
    'app.body.challenges.warning': '警告!',
    'app.body.challenges.options_delete': '本当にチャレンジを削除しますか？',
    'app.body.challenges.confirm_infor': '情報を確認する',
    'app.body.challenges.private_key': 'プライベートキー',
    'app.body.challenges.timezone': 'タイムゾーン',
    'app.body.challenges.enter_private_key': 'プライベートキー入力',
    'app.body.challenges.enter_address': 'アドレス入力',
    'app.body.challenges.receive.id': '受取人ID',
    'app.body.challenges.receive.amount': '受取人金額',
    'app.body.challenges.receive.type': '受取人タイプ',
    'app.body.challenges.receive.type.1': '成功',
    'app.body.challenges.receive.type.2': '失敗',
    'app.body.challenges.receive.wallet_address': '受取人ウォレットアドレス',
    'app.body.challenges.receive.dividend_percentage': '配分(%)',
    'app.body.challenges.receive.dividend_amount': '配分',
    'app.body.challenges.receive.username': '受取人ユーザー名',
    'app.body.challenges.receive.success': '（成功時）',
    'app.body.challenges.receive.failure': '（失敗時）',
    'app.body.challenges.register_step.registerEmail': 'E-mail登録',
    'app.body.challenges.register_step.enterPinCode': 'PIN入力',
    'app.body.challenges.register_step.agreeTermOfUse': '利用規約同意',
    'app.body.challenges.register_step.configTFA': '2段階認証設定',
    'app.body.challenges.register_step.finish': '終了',
    'app.body.challenges.register_step.0': '偽',
    'app.body.challenges.register_step.1': '真',
    'app.body.challenges.stepInfo.date':'日付',
    'app.body.challenges.stepInfo.pending':'Pending',
    'app.body.challenges.stepInfo.success':'Successful',
    'app.body.challenges.stepInfo.fail':'Failure',
    'app.body.challenges.stepInfo.standardTime':'標準時間',
    'app.body.challenges.stepInfo.steps':'歩数',
    'app.body.challenges.stepInfo.status':'状況',
    'app.body.challenges.stepInfo.toBlockchain':'送信状況',
    'app.body.challenges.stepInfo.stepHash':'歩数送信Hash',
    'app.body.challenges.stepInfo.sendDate':'送信日時',
    'app.body.challenges.stepInfo.time':'日本時間',
    'app.body.challenges.stepInfo.cleared':'Cleared',
    'app.body.challenges.mintAddress': 'Receiver address',
    'app.body.challenges.drap': 'ここにファイルドをラッグアンドドロップ、それともクリックしてファイルを選択してください。',
    'app.body.challenges.uploaded': 'アップロードしました',
    'app.body.challenges.finish_import': 'チャレンジのインポートが終了しました。',
    'app.body.challenges.import_challenge': 'チャレンジをインポート',
    'app.body.challenges.final_transaction_hash': 'チャレンジ最終取引ハッシュ',

    // Report
    'app.body.reports': 'レポート',
    'app.body.reports.listReport': 'レポートリスト',
    'app.body.reports.reporter': 'レポーター',
    'app.body.reports.reported': 'レポート済',
    'app.body.reports.reason': '理由',
    'app.body.reports.content': '内容',
    'app.body.reports.status': 'ステータス',
    'app.body.reports.note': 'ノート',
    'app.body.report.status.Open': 'オープン',
    'app.body.report.status.Done': '済',
    'app.body.report.status.Processing': '進行中',
    'app.body.reports.delete': ' 警告',
    'app.body.reports.notification': 'レポートIDを削除しますか？ ID:',

    'app.body.reports.lastUpdate': '最終アップデート',
    'app.body.reports.createdAt': '作成',

    // Transaction
    'app.body.transactions.history': 'トランザクションフィー履歴',
    'app.body.transactions.amount': '金額',
    'app.body.transactions.address': 'アドレス',
    'app.body.transactions.gas': 'ガス',
    'app.body.transactions.hash': 'ハッシュ',
    'app.body.transactions.status': 'ステータス',
    'app.body.transactions.created_at': '作成',
    'app.body.transactions.from_address': '送金元アドレス',
    'app.body.transactions.time_tamp': 'タイムスタンプ',

    // Wallet
    'app.body.wallets.id': 'ウォレットID',
    'app.body.wallets.wallet': 'ウォレット',
    'app.body.wallets.user_name': 'ユーザー名',
    'app.body.wallets.email': 'E-mail',
    'app.body.wallets.address': 'ウォレットアドレス',
    'app.body.wallets.created_at': '作成日時',
    'app.body.wallets.qr_code': 'QRコード',
    'app.body.wallets.detail': '詳細',
    'app.body.wallets.user_id': 'ID ユーザー',
    'app.body.wallets.username': '受取人ユーザー名',
    'app.body.wallets.updated_at': 'アップデート',
    'app.body.wallets.wallet_qr_code': 'ウォレットQRコード',
    
    // Networks
    'app.body.networks.id': 'ネットワークID',
    'app.body.networks.url': 'RPC Backend',
    'app.body.networks.url_cronjob': 'RPC Cronjob',
    'app.body.networks.name': 'Name',

    //Contract version
    'app.body.contractVersions.create': '契約バージョンを作成',
    'app.body.contractVersions.detail': '詳しい契約バージョン',
    'app.body.contractVersions.edit': '契約バージョン編集',
    'app.body.contractVersions.id': 'ID',
    'app.body.contractVersions.user_id': 'ID ユーザー',
    'app.body.contractVersions.version': 'バージョン',
    'app.body.contractVersions.abi': 'ABI',
    'app.body.contractVersions.byte_code': 'バイトコード',
    'app.body.contractVersions.created_at': '作成',
    'app.body.contractVersions.updated_at': 'アップデート',
    'app.body.contractVersions.delete': ' 警告',
    'app.body.contractVersions.notification': '契約バージョンを解除したい？ ID: ',
    'app.body.contractVersions.enter_version': '入る版 バージョン',
    'app.body.contractVersions.enter_abi': 'ABI入力',
    'app.body.contractVersions.file': 'バイトコードファイルを選択',
    'app.body.contractVersions.change_file': 'バイトコードファイルを更新',
    'app.body.contractVersions.dowload': 'ファイルをダウンロード',

    // Table header
    'app.body.header.id': 'ID',
    'app.body.header.avatar': 'アバター',
    'app.body.header.name': '名前',
    'app.body.header.email': 'E-mail',
    'app.body.header.role': '役割',
    'app.body.header.status': 'ステータス',
    'app.body.header.registedAt': '登録日時',
    'app.body.header.options': 'オプション',
    'app.body.header.title': 'タイトル',
    'app.body.header.banner': 'バナー',
    'app.body.header.createdAt': '作成',
    'app.body.header.description': '説明',
    'app.body.header.user': 'ユーザー',
    'app.body.header.platform': 'プラットフォーム',
    'app.body.header.version': 'バージョン',
    'app.body.header.environment': '環境',
    'app.body.header.reporter': 'レポーター',
    'app.body.header.reported': 'レポート済',
    'app.body.header.reason': '理由',
    'app.body.header.content': '内容',
    'app.body.header.note': 'ノート',
    'app.body.header.filter': 'フィルタ',
    'app.body.header.detail': '詳細',
    'app.body.header.notFound': '読み込み中...',

    // Table row
    'app.body.id': 'ID',
    'app.body.avatar': 'アバター',
    'app.body.options': 'オプション',
    'app.body.row.user': 'ユーザー',
    'app.body.name': '名前',
    'app.body.email': 'E-mail',
    'app.body.role': '役割',
    'app.body.status': '状態',
    'app.body.registedAt': '登録',

    // Daily Step
    'app.body.dailyStep.challenger_id': 'Challenger ID',
    'app.body.dailyStep.challenger_username': 'Challenger UserName',
    'app.body.dailyStep.date': 'Date',
    'app.body.dailyStep.step': 'Steps',
    'app.body.dailyStep.time_send_step': 'Time of sending step data',
    'app.body.dailyStep.step_hash': 'Step Data Hash',
    'app.body.dailyStep.challenge_id': 'Challenge ID',
    'app.body.dailyStep.challenge_name': 'Challenge name',
    'app.body.dailyStep.challenge_hash': 'Challenge hash',
    'app.body.dailyStep.challenge_from': 'Challenge From',
    'app.body.dailyStep.challenge_to': 'Challenge To',

    // Status
    'app.body.status.all': '全て',

    // Environment
    'app.env.dev': '開発',
    'app.env.sta': 'ステージ',
    'app.env.pro': 'プロダクト',

    // Action
    'app.action.delete': '削除',
    'app.action.stop': 'ストップ',
    'app.action.update': 'アップデート',
    'app.action.submit': '投稿',
    'app.action.edit': '編集',
    'app.action.search': '検索',
    'app.action.save': '保存',
    'app.action.cancel': 'キャンセル',
    'app.action.destroy_challenge': 'チャレンジを破棄',
    'app.action.close': '終了',
    'app.action.add': '追加',
    'app.action.create': '作成',
    'app.action.pending': '保留',
    'app.action.mint.token': 'Mint Tanimo Token',
    'app.action.daily.step': 'Go to daily step',
    'app.action.export': 'Export CSV',
    'app.action.upload': 'アップロード',
    'app.action.upload.metamask': 'メタマスクをアップロードする',
    'app.action.import': 'インポート',

    // Message
    'app.message.banner.create_success': 'バナー作成成功',
    'app.message.banner.delete_success': 'バナー削除成功',
    'app.message.banner.update_success': 'バナー更新成功',

    'app.message.user.create_success': 'ユーザー作成成功',
    'app.message.user.delete_success': 'ユーザー削除成功',
    'app.message.user.update_success': 'ユーザー更新成功',

    'app.message.admin.create_success': 'ユーザー作成成功',
    'app.message.admin.delete_success': 'ユーザー削除成功',
    'app.message.admin.update_success': 'ユーザー更新成功',
    'app.message.admin.password_incorrect': '古いパスワードが間違っています',
    'app.message.admin.password_validate': '無効なパスワード',
    'app.message.admin.err_pass': 'パスワードの長さが8以上',
    'app.message.admin.err_confirm_pass': 'パスワードエラーを確認!',
    'app.message.admin.null':' ',

    'app.message.news.create_success': 'ニュース作成成功',
    'app.message.news.delete_success': 'ニュース削除成功',
    'app.message.news.update_success': 'ニュース更新成功',

    'app.message.version.create_success': 'バージョン作成成功',
    'app.message.version.delete_success': 'バージョン削除成功',
    'app.message.version.update_success': 'バージョン更新成功',
    'app.message.version.not_defined': 'バージョンが確定できない!',

    'app.message.exercise.create_success': 'エクササイズ作成成功',
    'app.message.exercise.delete_success': 'エクササイズ削除成功',
    'app.message.exercise.update_success': 'エクササイズ更新成功',

    'app.message.challenge.err': '破棄送信エラー',
    'app.message.challenge.success': '破棄送信成功',
    'app.message.challenge.address_false': 'スポンサーまたはサーバーのアドレスではありません',
    'app.message.challenge.address_invalid': 'アドレスが正しくありません',
    'app.message.challenges.value_error': '不正なデータ',
    'app.message.challengeHash_exist': '破棄を続けられません !',
    'app.message.challenges.null': ' ',
    'app.message.challenges.update_success': 'UPDATE CHALLENGE SUCCESS',

    'app.message.report.update_success': 'レポート更新成功',
    'app.message.report.delete_success': 'レポート削除成功',

    'app.message.contractVersions.create_success': '契約バージョンを削除した',
    'app.message.contractVersions.delete_success': '契約バージョンを削除した',
    'app.message.contractVersions.update_success': '契約バージョンを更新した',
    'app.message.contractVersions.version_not_defined': 'バージョンが確定できない !',
    'app.message.mint.success': 'Mint coin success',
    'app.message.mint.error': 'Mint coin error',

    // Common
    'app.body.true': '真',
    'app.body.false': '偽',
    'app.body.loadmore': '更に読み込む',
    'app.common.total': '合計',

    // Pagination
    'app.pagination.pre': '前',
    'app.pagination.next': '次へ',
  },
};
