import {
  GET_LIST_NEWS_DOING,
  GET_LIST_NEWS_FAILURE,
  GET_LIST_NEWS_SUCCESS,

  DELETE_NEWS_DOING,
  DELETE_NEWS_FAILURE,
  DELETE_NEWS_SUCCESS,

  CREATE_NEWS_DOING,
  CREATE_NEWS_FAILURE,
  CREATE_NEWS_SUCCESS,

  UPDATE_NEWS_DOING,
  UPDATE_NEWS_FAILURE,
  UPDATE_NEWS_SUCCESS
} from './NewsAction';

const initialState = {
  loading: false,
  error: '',
  news: [],
  total: 0
}

const newsReducer = (state = initialState, action = {}) => {
  switch(action.type) {
    case GET_LIST_NEWS_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_LIST_NEWS_SUCCESS: {
      return {
        ...state,
        loading: false,
        news: action.payload.results,
        total: action.payload.total
      }
    }

    case GET_LIST_NEWS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case DELETE_NEWS_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case DELETE_NEWS_SUCCESS: {
      return {
        ...state,
        loading: false,
        news: state.news.filter(news => news.id !== action.payload.id),
        total: state.total - 1
      }
    }

    case DELETE_NEWS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case CREATE_NEWS_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case CREATE_NEWS_SUCCESS: {
      return {
        ...state,
        loading: false,
        news: [...state.news, action.payload],
        total: state.total + 1
      }
    }

    case CREATE_NEWS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case UPDATE_NEWS_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case UPDATE_NEWS_SUCCESS: {
      const news = state.news.map(aNew => {
        if (news.id === action.payload.id) {
          return action.payload;
        }

        return aNew;
      });

      return {
        ...state,
        loading: false,
        news
      }
    }

    case UPDATE_NEWS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    default:
      return state;
  }
}

export default newsReducer;
