import { ENGLISH } from './en';
import { JAPAN } from './ja';
import storage from '../../../utils/storage';

const getMessages = lang => {
    switch(lang) {
        
        case ENGLISH.lang: {
            return ENGLISH.messages
        }

        case JAPAN.lang: {
            return JAPAN.messages
        }

        default: return ENGLISH.messages
    }
}

const getMessageById = id => {
    const lang = storage.getLang() || 'en';
    const messages = getMessages(lang);
    return messages[id];
}

export {
    getMessages,
    getMessageById
}