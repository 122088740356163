import axios from '../../utils/axios';

const NetworksService =  {

  async getAllNetworks() {
    try {
      const response = await axios.get(`/users/settings/all-networks`);
      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  },

}

export default NetworksService
