import {
  GET_LIST_REPORT_DOING,
  GET_LIST_REPORT_FAILURE,
  GET_LIST_REPORT_SUCCESS,

  DELETE_REPORT_DOING,
  DELETE_REPORT_FAILURE,
  DELETE_REPORT_SUCCESS,

  UPDATE_REPORT_DOING,
  UPDATE_REPORT_FAILURE,
  UPDATE_REPORT_SUCCESS
} from './ReportAction';

const initialState = {
  loading: false,
  error: '',
  reports: [],
  total: 0
}

const reportReducer = (state = initialState, action = {}) => {
  switch(action.type) {
    case GET_LIST_REPORT_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_LIST_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        reports: action.payload.results,
        total: action.payload.total
      }
    }

    case GET_LIST_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case DELETE_REPORT_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case DELETE_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        reports: state.reports.filter(report => report.id !== action.payload.id),
        total: state.total - 1
      }
    }

    case DELETE_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case UPDATE_REPORT_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case UPDATE_REPORT_SUCCESS: {
      const reports = state.reports.map(report => {
        if (report.id === action.payload.id) {
          report = {
            ...report,
            ...action.payload
          }
        }

        return report;
      });

      return {
        ...state,
        loading: false,
        reports
      }
    }

    case UPDATE_REPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    default:
      return state;
  }
}

export default reportReducer;
