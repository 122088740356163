import axios from '../../utils/axios';
import { LIMIT } from '../../utils/config';

const ReportService = {

  async getListReport(page = 1, limit = LIMIT, search = '') {
    try {
      const response = await axios.get('/reports', {
        params: {
          page,
          limit,
          search,
        }
      })
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async getById(id) {
    try {
      const response = await axios.get(`/reports/${id}`);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async deleteById(id) {
    try {
      const response = await axios.delete(`/reports/${id}`);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  
  /**
   * Update by id
   * @param {number} id id of user
   * @param {Object} model data to update
   */
  async updateById(id, model) {
    try {
      const response = await axios.put(`/reports/${id}`, model);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
}

export default ReportService;
