import NewsService from './NewsService';
import S3Service from '../S3/S3Service';
import { toast } from 'react-toastify';
import { TYPE_CREATE_BANNER } from '../../utils/config';
import {getMessageById} from '../Multilang/locales';

/** Get list banners action */
export const GET_LIST_NEWS_SUCCESS = 'GET_LIST_NEWS_SUCCESS';
const getListNewsSuccess = (result) => {
  return { type: GET_LIST_NEWS_SUCCESS, payload: result }
}

export const GET_LIST_NEWS_DOING = 'GET_LIST_NEWS_DOING';
const getListNewsDoing = () => {
  return { type: GET_LIST_NEWS_DOING }
}

export const GET_LIST_NEWS_FAILURE = 'GET_LIST_NEWS_FAILURE';
const getListNewsFail = (error) => {
  return { type: GET_LIST_NEWS_FAILURE, error }
}

/**
 * @param {number} page page number
 * @param {number} limit limit number
 */
export const getListNews = (page, limit, search) => {
  return async dispatch => {

    dispatch(getListNewsDoing());

    return NewsService.getListNews(page, limit, search)
      .then(response => {
        if (!response.error) {
          return dispatch(getListNewsSuccess(response.data));
        }

        return dispatch(getListNewsFail(response));
      })
  }
}

/** Delete by id */
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
const deleteByIdSuccess = (result) => {
  toast.success(getMessageById('app.message.news.delete_success'));
  return { type: DELETE_NEWS_SUCCESS, payload: result }
}

export const DELETE_NEWS_DOING = 'DELETE_NEWS_DOING';
const deleteByIdDoing = () => {
  return { type: DELETE_NEWS_DOING }
}

export const DELETE_NEWS_FAILURE = 'DELETE_NEWS_FAILURE';
const deleteByIdFail = (error) => {
  toast.error(error.message);
  return { type: DELETE_NEWS_FAILURE, error }
}

export const deleteById = (id) => {
  return async dispatch => {
    dispatch(deleteByIdDoing());

    return NewsService.deleteById(id)
      .then(response => {
        if (!response.error) {
          return dispatch(deleteByIdSuccess({ id }));
        }

        return dispatch(deleteByIdFail(response));
      })
  }
}

/** Create */
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';
const createSuccess = (result) => {
  toast.success(getMessageById('app.message.news.create_success'));
  return { type: CREATE_NEWS_SUCCESS, payload: result }
}

export const CREATE_NEWS_DOING = 'CREATE_NEWS_DOING';
const createDoing = () => {
  return { type: CREATE_NEWS_DOING }
}

export const CREATE_NEWS_FAILURE = 'CREATE_NEWS_FAILURE';
const createFail = (error) => {
  toast.error(error.message);
  return { type: CREATE_NEWS_FAILURE, error }
}

export const create = ({ imageFile, typeCreateBanner, ...model }) => {
  return async dispatch => {
    dispatch(createDoing());

    /** Upload image when create banner using file */
    if (typeCreateBanner === TYPE_CREATE_BANNER.file) {
      const response = await S3Service.uploadToS3(imageFile);

      if (!response.error) {
        model.banner = response.data.endpoint;
      } else {
        return dispatch(createFail(response));
      }
    }

    return NewsService.create(model)
      .then(response => {
        if (!response.error) {
          return dispatch(createSuccess(response.data));
        }

        return dispatch(createFail(response));
      })
  }
}

/** Update */
export const UPDATE_NEWS_SUCCESS = 'UPDATE_NEWS_SUCCESS';
const updateByIdSuccess = (result) => {
  toast.success(getMessageById('app.message.news.update_success'));
  return { type: UPDATE_NEWS_SUCCESS, payload: result }
}

export const UPDATE_NEWS_DOING = 'UPDATE_NEWS_DOING';
const updateByIdDoing = () => {
  return { type: UPDATE_NEWS_DOING }
}

export const UPDATE_NEWS_FAILURE = 'UPDATE_NEWS_FAILURE';
const updateByIdFail = (error) => {
  toast.error(error.message);
  return { type: UPDATE_NEWS_FAILURE, error }
}

export const updateById = (id, model) => {
  return async dispatch => {
    dispatch(updateByIdDoing());

    return NewsService.updateById(id, model)
      .then(response => {
        if (!response.error) {
          return dispatch(updateByIdSuccess(response.data));
        }

        return dispatch(updateByIdFail(response));
      })
  }
}

