import {
  GET_LIST_ADMINS_DOING,
  GET_LIST_ADMINS_FAILURE,
  GET_LIST_ADMINS_SUCCESS,

  DELETE_ADMIN_DOING,
  DELETE_ADMIN_FAILURE,
  DELETE_ADMIN_SUCCESS,

  CREATE_ADMIN_DOING,
  CREATE_ADMIN_FAILURE,
  CREATE_ADMIN_SUCCESS,

  UPDATE_ADMIN_DOING,
  UPDATE_ADMIN_FAILURE,
  UPDATE_ADMIN_SUCCESS
} from './AdminAction';

const initialState = {
  loading: false,
  error: '',
  admins: [],
  total: 0
}

const adminReducer = (state = initialState, action = {}) => {
  switch(action.type) {
    case GET_LIST_ADMINS_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_LIST_ADMINS_SUCCESS: {
      return {
        ...state,
        loading: false,
        admins: action.payload.results,
        total: action.payload.total
      }
    }

    case GET_LIST_ADMINS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case DELETE_ADMIN_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case DELETE_ADMIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        admins: state.admins.filter(admin => admin.id !== action.payload.id),
        total: state.total - 1
      }
    }

    case DELETE_ADMIN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case CREATE_ADMIN_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case CREATE_ADMIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        admins: [...state.admins, action.payload],
        total: state.total + 1
      }
    }

    case CREATE_ADMIN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case UPDATE_ADMIN_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case UPDATE_ADMIN_SUCCESS: {
      const admins = state.admins.map(admin => {
        if (admin.id === action.payload.id) {
          admin = {
            ...admin,
            ...action.payload
          }
        }

        return admin;
      });

      return {
        ...state,
        loading: false,
        admins
      }
    }

    case UPDATE_ADMIN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    default:
      return state;
  }
}

export default adminReducer;
