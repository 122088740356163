import axios from '../../utils/axios';
import { LIMIT_EXPORT, LIMIT_SHOW } from '../../utils/config';

const ChallengeService = {

  async getListChallenge(page = 1, limit = LIMIT_SHOW, search = '') {
      try{
        const response = await axios.get('/challenges', {
          params: {
            page,
            limit,
            search,
          }
        })
        return response.data;
      } catch (e) {
        console.log(e)
        throw e;
      }
  },
  async getListChallengeExport(page = 1, limit = LIMIT_EXPORT, search = '') {
    try{
      const response = await axios.get('/challenges', {
        params: {
          page,
          limit,
          search,
        }
      })
      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
},
  async getById(id) {
      try {
        const response = await axios.get(`/challenges/${id}`);
        return response.data;
      } catch (e) {
        console.log(e)
        throw e;
      }
  },

  /**
   * Update by id
   * @param {number} id challenge
   * @param {Object} model data to update
   */
  async updateById(id, model) {
    try {
      const response = await axios.put(`/challenges/${id}/destroy`, model);
      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  },

  async getUserByAddress(address) {
    try {
      const response = await axios.get(`users/get-profile-by-wallet/${address}`);
      return response.data.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  },

  async createChallenge(data) {
    const response = await axios.post(`/challenges`, data)
    return response.data;
  },

  async editNoteChallenge(id, note) {
    const params = {
      note
    }
    const response = await axios.patch(`/admin/challenges/${id}/note`, params);
    return response.data.data
  },

  async getFeePercent(network) {
    try {
      const response = await axios.get(`/challenges/get-fee-percent?network_id=${network}`);
      return response.data.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  }
}

export default ChallengeService;
