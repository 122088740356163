import axios from '../../utils/axios';

const AuthService = {

  async login({ email, password }) {
    try {
      const response = await axios.post('admin/auth/login', {
        email,
        password
      });

      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  },

  async getCurrentUserInfo() {
    try {
      const response = await axios.get('/users/me');
      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  },

  async login2fa({ email, password }) {
    try {
      const response = await axios.post('admin/auth/login-2fa', {
        email,
        password
      });

      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  },

  async verifyLogin2fa ({code, token}) {
    try {
      const response = await axios.post('admin/auth/verify-login-2fa', {
        code,
        token
      });

      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  },

  async sendEmailForget({ email }) {
    try {
      const response = await axios.post('/admin/auth/reset-password/send-code', {
        email
      });

      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  },

  async verifyCodeForgetPassword({ code, token }) {
    try {
      const response = await axios.post('/admin/auth/reset-password/verify-code', {
        code,
        token
      });

      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  },

  async updatePasswordSA(model) {
    try {
      const response = await axios.put('/admin/auth/change-password', model);
      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  }

}

export default AuthService;
