import VersionService from './VersionService';
import { toast } from 'react-toastify';
import {getMessageById} from '../Multilang/locales';

/** Get list version action */
export const GET_LIST_VERSION_SUCCESS = 'GET_LIST_VERSION_SUCCESS';
const getListVersionSuccess = (result) => {
  return { type: GET_LIST_VERSION_SUCCESS, payload: result}
}

export const GET_LIST_VERSION_DOING = 'GET_LIST_VERSION_DOING';
const getListVersionDoing = () => {
  return { type: GET_LIST_VERSION_DOING }
}

export const GET_LIST_VERSION_FAILURE = 'GET_LIST_VERSION_FAILURE';
const getListVersionFail = (error) => {
  return { type: GET_LIST_VERSION_FAILURE, error }
}

/**
 * @param {number} page page number
 * @param {number} limit limit number
 */
export const getListVersions = ( page, limit, search) => {
  return async dispatch => {

    dispatch(getListVersionDoing());

    return VersionService.getListVersions(page, limit, search)
      .then(response => {
        if (!response.error) {
          return dispatch(getListVersionSuccess(response.data));
        }

        return dispatch(getListVersionFail(response));
      })
  }
}

/** Delete by id */
export const DELETE_VERSION_SUCCESS = 'DELETE_VERSION_SUCCESS';
const deleteByIdSuccess = (result) => {
  toast.success(getMessageById('app.message.version.delete_success'));
  return { type: DELETE_VERSION_SUCCESS, payload: result }
}

export const DELETE_VERSION_DOING = 'DELETE_VERSION_DOING';
const deleteByIdDoing = () => {
  return { type: DELETE_VERSION_DOING }
}

export const DELETE_VERSION_FAILURE = 'DELETE_VERSION_FAILURE';
const deleteByIdFail = (error) => {
  toast.error(error.message);
  return { type: DELETE_VERSION_FAILURE, error }  
}

export const deleteById = (id) => {
  return async dispatch => {
    dispatch(deleteByIdDoing());

    return VersionService.deleteById(id)
      .then(response => {
        if (!response.error) {
          return dispatch(deleteByIdSuccess({ id }));
        }

        return dispatch(deleteByIdFail(response));
      })
  }
}

/** Create */
export const CREATE_VERSION_SUCCESS = 'CREATE_VERSION_SUCCESS';
const createSuccess = (result) => {
  toast.success(getMessageById('app.message.version.create_success'));
  return { type: CREATE_VERSION_SUCCESS, payload: result }
}

export const CREATE_VERSION_DOING = 'CREATE_VERSION_DOING';
const createDoing = () => {
  return { type: CREATE_VERSION_DOING }
}

export const CREATE_VERSION_FAILURE = 'CREATE_VERSION_FAILURE';
const createFail = (error) => {
  toast.error(error.message);
  return { type: CREATE_VERSION_FAILURE, error }  
}

export const create = (model) => {
  return async dispatch => {
    dispatch(createDoing());

    return VersionService.create(model)
      .then(response => {
        if (!response.error) {
          return dispatch(createSuccess(response.data));
        }

        return dispatch(createFail(response));
      })
  }
}

/** Update */
export const UPDATE_VERSION_SUCCESS = 'UPDATE_VERSION_SUCCESS';
const updateByIdSuccess = (result) => {
  toast.success(getMessageById('app.message.version.update_success'));
  return { type: UPDATE_VERSION_SUCCESS, payload: result }
}

export const UPDATE_VERSION_DOING = 'UPDATE_VERSION_DOING';
const updateByIdDoing = () => {
  return { type: UPDATE_VERSION_DOING }
}

export const UPDATE_VERSION_FAILURE = 'UPDATE_VERSION_FAILURE';
const updateByIdFail = (error) => {
  toast.error(error.message);
  return { type: UPDATE_VERSION_FAILURE, error }  
}

export const updateById = (id, model) => {
  return async dispatch => {
    dispatch(updateByIdDoing());

    return VersionService.updateById(id, model)
      .then(response => {
        if (!response.error) {
          return dispatch(updateByIdSuccess(response.data));
        }

        return dispatch(updateByIdFail(response));
      })
  }
}

