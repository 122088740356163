const Storage = {
  getToken() {
    return localStorage.getItem('token');
  },

  setToken(token) {
    localStorage.setItem('token', token);
  },

  deleteToken() {
    localStorage.removeItem('token');
  },

  clear() {
    localStorage.clear();
  },
  
  getLang() {
    return localStorage.getItem('lang');
  },

  setLang(lang) {
      localStorage.setItem('lang',lang);
  },
}

export default Storage;
