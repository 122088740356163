import DailyStepService from './DailyStepService';

export const GET_DAILY_STEPS_SUCCESS = 'GET_DAILY_STEPS_SUCCESS';
const getAllDailyStepSuccess = (result) => {
    return { type: GET_DAILY_STEPS_SUCCESS, payload: result }
}

export  const GET_DAILY_STEP_DOING = 'GET_DAILY_STEP_DOING';
const getAllDailyStepDoing = (result) => {
    return { type: GET_DAILY_STEP_DOING, payload: result }
}

export const GET_DAILY_STEP_FAILURE = 'GET_DAILY_STEP_FAILURE';
const getAllDailyStepFail = (error) => {
    return { type: GET_DAILY_STEP_FAILURE, error }
}

/**
 * @param {number} type type get challenges. 1: sponsor. 2: challenger
 * @param {number} page page number
 * @param {number} limit limit number
 */
export const getAllDailySteps = (page, limit) => {
    return async dispatch => {

      dispatch(getAllDailyStepDoing());

      return DailyStepService.getAllDailySteps(page, limit)
      .then(response => {
        if (!response.error) {
            return dispatch(getAllDailyStepSuccess(response.data));
        }

        return dispatch(getAllDailyStepFail(response));
      })
    }
}