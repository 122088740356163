import { ACTION_TYPE_LOCALE } from './LocaleAction';
import { LANG } from '../../utils/config';
import storage from '../../utils/storage';
import { getMessages } from './locales';
const localLang = storage.getLang() || LANG.en;

const initialState = {
  lang: localLang,
  messages: getMessages(localLang)
}

const localeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    
    case ACTION_TYPE_LOCALE.CHANGE_LANG: {
      return {
        ...state,
        lang: action.lang,
        messages: getMessages(action.lang)
      }
    }

    default:
      return state;
  }
}

export default localeReducer;
