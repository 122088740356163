import axios from '../../utils/axios';
import { LIMIT } from '../../utils/config';

const ContractVersionService = {

  async getListContractVersions(page = 1, limit = LIMIT, search = '') {
    try {
      const response = await axios.get(`/admin/contract-versions?page=${page}&limit=${limit}&search=${search}`);

      return response.data;
    } catch (e) {
      console.log(e);      
      throw e;
    }
  },

  async getById(id) {
    try {
      const response = await axios.get(`/admin/contract-versions/${id}`);
      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  },

  async deleteById(id) {
    try {
      const response = await axios.delete(`/admin/contract-versions/${id}`);
      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  },

  async create(model) {
    try {
      const response = await axios.post('/admin/contract-versions', model);
      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  },

  /**
   * Update by id
   * @param {number} id
   * @param {Object} model data to update
   */
  async updateById(id, model) {
    try {
      const response = await axios.put(`/admin/contract-versions/${id}`, model);
      return response.data;
    } catch (e) {
      console.log(e)
      throw e;
    }
  }
}

export default ContractVersionService;
