import ContractVersionService from './ContractVersionService';
import S3Service from '../S3/S3Service';
import { toast } from 'react-toastify';
import {getMessageById} from '../Multilang/locales';

/** Get list contractversions action */
export const GET_LIST_CONTRACTVERSIONS_SUCCESS = 'GET_LIST_CONTRACTVERSIONS_SUCCESS';
const getListContractVersionsSuccess = (result) => {
  return { type: GET_LIST_CONTRACTVERSIONS_SUCCESS, payload: result }
}

export const GET_LIST_CONTRACTVERSIONS_DOING = 'GET_LIST_CONTRACTVERSIONS_DOING';
const getListContractVersionsDoing = () => {
  return { type: GET_LIST_CONTRACTVERSIONS_DOING }
}

export const GET_LIST_CONTRACTVERSIONS_FAILURE = 'GET_LIST_CONTRACTVERSIONS_FAILURE';
const getListContractVersionsFail = (error) => {
  return { type: GET_LIST_CONTRACTVERSIONS_FAILURE, error }
}

/**
 * @param {number} page page number
 * @param {number} limit limit number
 */
export const getListContractVersions = (page, limit, search) => {
  return async dispatch => {

    dispatch(getListContractVersionsDoing());

    return ContractVersionService.getListContractVersions(page, limit, search)
      .then(response => {
        if (!response.error) {
          return dispatch(getListContractVersionsSuccess(response.data));
        }

        return dispatch(getListContractVersionsFail(response));
      })
  }
}

/** Delete by id */
export const DELETE_CONTRACTVERSION_SUCCESS = 'DELETE_CONTRACTVERSION_SUCCESS';
const deleteByIdSuccess = (result) => {
  toast.success(getMessageById('app.message.contractVersions.delete_success'));
  return { type: DELETE_CONTRACTVERSION_SUCCESS, payload: result }
}

export const DELETE_CONTRACTVERSION_DOING = 'DELETE_CONTRACTVERSION_DOING';
const deleteByIdDoing = () => {
  return { type: DELETE_CONTRACTVERSION_DOING }
}

export const DELETE_CONTRACTVERSION_FAILURE = 'DELETE_CONTRACTVERSION_FAILURE';
const deleteByIdFail = (error) => {
  toast.error(error.message);
  return { type: DELETE_CONTRACTVERSION_FAILURE, error }
}

export const deleteById = (id) => {
  return async dispatch => {
    dispatch(deleteByIdDoing());

    return ContractVersionService.deleteById(id)
      .then(response => {
        if (!response.error) {
          return dispatch(deleteByIdSuccess({ id }));
        }

        return dispatch(deleteByIdFail(response));
      })
  }
}

/** Create */
export const CREATE_CONTRACTVERSION_SUCCESS = 'CREATE_CONTRACTVERSION_SUCCESS';
const createSuccess = (result) => {
  toast.success(getMessageById('app.message.contractVersions.create_success'));
  return { type: CREATE_CONTRACTVERSION_SUCCESS, payload: result }
}

export const CREATE_CONTRACTVERSION_DOING = 'CREATE_CONTRACTVERSION_DOING';
const createDoing = () => {
  return { type: CREATE_CONTRACTVERSION_DOING }
}

export const CREATE_CONTRACTVERSION_FAILURE = 'CREATE_CONTRACTVERSION_FAILURE';
const createFail = (error) => {
  toast.error(error.message);
  return { type: CREATE_CONTRACTVERSION_FAILURE, error }
}

export const create = ({ file, ...model }) => {
  return async dispatch => {
    dispatch(createDoing());

    /** Upload image when create contractversion using file */
    const response = await S3Service.uploadToS3(file);

    if(!response.error) {
      model.byteCode = response.data.endpoint;
    } else {
      return dispatch(createFail(response));
    }

    return ContractVersionService.create(model)
      .then(response => {
        if (!response.error) {
          return dispatch(createSuccess(response.data));
        }

        return dispatch(createFail(response));
      })
  }
}

/** Update */
export const UPDATE_CONTRACTVERSION_SUCCESS = 'UPDATE_CONTRACTVERSION_SUCCESS';
const updateByIdSuccess = (result) => {
  toast.success(getMessageById('app.message.contractVersion.update_success'));
  return { type: UPDATE_CONTRACTVERSION_SUCCESS, payload: result }
}

export const UPDATE_CONTRACTVERSION_DOING = 'UPDATE_CONTRACTVERSION_DOING';
const updateByIdDoing = () => {
  return { type: UPDATE_CONTRACTVERSION_DOING }
}

export const UPDATE_CONTRACTVERSION_FAILURE = 'UPDATE_CONTRACTVERSION_FAILURE';
const updateByIdFail = (error) => {
  toast.error(error.message);
  return { type: UPDATE_CONTRACTVERSION_FAILURE, error }
}

export const updateById = (id, model) => {
  return async dispatch => {
    dispatch(updateByIdDoing());

    return ContractVersionService.updateById(id, model)
      .then(response => {
        if (!response.error) {
          return dispatch(updateByIdSuccess(response.data));
        }

        return dispatch(updateByIdFail(response));
      })
  }
}

