import {
  GET_ALL_NETWORKS_DOING,
  GET_ALL_NETWORKS_SUCCESS,
  GET_ALL_NETWORKS_FAILURE
} from './NetworksAction';

const initialState = {
  loading: false,
  error: '',
}

const networksReducer = (state = initialState, action = {}) => {
switch(action.type) {
  case GET_ALL_NETWORKS_DOING : {
    return {
      ...state,
      loading: true
    }
  }

  case GET_ALL_NETWORKS_SUCCESS: {
    return {
      ...state,
      networks: action.payload,
      loading: false,
    }
  }

  case GET_ALL_NETWORKS_FAILURE: {
    return {
      ...state,
      loading: false,
      error: action.error.message
    }
  }

  default:
    return state;
  }
}

export default networksReducer;
