import storage from '../../utils/storage';

export const ACTION_TYPE_LOCALE = {
  CHANGE_LANG: 'CHANGE_LANG',
};

// Action change local languge
export const changeLang = (lang) => {
  storage.setLang(lang);
  return {
    type: ACTION_TYPE_LOCALE.CHANGE_LANG,
    lang
  }
}
