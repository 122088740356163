import {
    GET_LIST_CHALLENGE_DOING,
    GET_LIST_CHALLENGE_SUCCESS,
    GET_LIST_CHALLENGE_FAILURE,
    GET_LIST_CHALLENGE_EXPORT_DOING,
    GET_LIST_CHALLENGE_EXPORT_SUCCESS,
    GET_LIST_CHALLENGE_EXPORT_FAILURE
} from './ChallengeAction';

const initialState = {
  loading: false,
  error: '',
  challenges: [],
  challengesExport: [],
  total: 0,
  totalExport: 0
}

const challengeReducer = (state = initialState, action = {}) => {
  switch(action.type) {
    case GET_LIST_CHALLENGE_DOING:
    case GET_LIST_CHALLENGE_EXPORT_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_LIST_CHALLENGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        challenges: action.payload.results,
        total: action.payload.total
      }
    }

    case GET_LIST_CHALLENGE_EXPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        challengesExport: action.payload.results,
        totalExport: action.payload.total
      }
    }

    case GET_LIST_CHALLENGE_FAILURE:
    case GET_LIST_CHALLENGE_EXPORT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }
    
    default:
      return state;
  }
}

export default challengeReducer;