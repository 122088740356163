import NetwroksService from './NetworksService';

export const GET_ALL_NETWORKS_SUCCESS = 'GET_ALL_NETWORKS_SUCCESS';
const getAllNetworksSuccess = (result) => {
    return { type: GET_ALL_NETWORKS_SUCCESS, payload: result }
}

export  const GET_ALL_NETWORKS_DOING = 'GET_ALL_NETWORKS_DOING';
const getAllNetworksDoing = (result) => {
    return { type: GET_ALL_NETWORKS_DOING, payload: result }
}

export const GET_ALL_NETWORKS_FAILURE = 'GET_ALL_NETWORKS_FAILURE';
const getAllNetworksFail = (error) => {
    return { type: GET_ALL_NETWORKS_FAILURE, error }
}

/**
 * @param {number} type type get challenges. 1: sponsor. 2: challenger
 * @param {number} page page number
 * @param {number} limit limit number
 */
export const getAllNetwork = () => {
    return async dispatch => {

      dispatch(getAllNetworksDoing());

      return NetwroksService.getAllNetworks()
      .then(response => {
        if (!response.error) {
            return dispatch(getAllNetworksSuccess(response.data));
        }

        return dispatch(getAllNetworksFail(response));
      })
    }
}
