import axios from '../../utils/axios';
import { LIMIT } from '../../utils/config';

const NewsService = {

  async getListNews(page = 1, limit = LIMIT, search = '') {
    try {
      const response = await axios.get('/news', {
        params: {
          page,
          limit,
          search,
        }
      })
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async getById(id) {
    try {
      const response = await axios.get(`/news/${id}`);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async deleteById(id) {
    try {
      const response = await axios.delete(`/news/${id}`);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  async create(model) {
    try {
      const response = await axios.post('/news', model);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /**
   * Update by id
   * @param {number} id id of news
   * @param {Object} model data to update
   */
  async updateById(id, model) {
    try {
      const response = await axios.put(`/news/${id}`, model);
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
}

export default NewsService;
