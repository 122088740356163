import axios from '../../utils/axios';
import s3InstanceAxios from 'axios';

const S3Service = {
  async uploadToS3(file) {
    try {

      let response = await axios.post('/s3/singed-url', {
        extension: file.type.split('/')[1]
      });

      response = response.data;

      if (!response.error) {
        await s3InstanceAxios.put(`${response.data.url_upload}`, file);
      }

      return response;
    } catch (e) {
      return {
        error: 1,
        message: e.message
      }
    }
  }
}

export default S3Service;
