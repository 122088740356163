import {
  GET_LIST_USERS_DOING,
  GET_LIST_USERS_FAILURE,
  GET_LIST_USERS_SUCCESS,

  DELETE_USER_DOING,
  DELETE_USER_FAILURE,
  DELETE_USER_SUCCESS,

  CREATE_USER_DOING,
  CREATE_USER_FAILURE,
  CREATE_USER_SUCCESS,

  UPDATE_USER_DOING,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS
} from './UserAction';

const initialState = {
  loading: false,
  error: '',
  users: [],
  total: 0
}

const userReducer = (state = initialState, action = {}) => {
  switch(action.type) {
    case GET_LIST_USERS_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_LIST_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: action.payload.results,
        total: action.payload.total
      }
    }

    case GET_LIST_USERS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case DELETE_USER_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: state.users.filter(user => user.id !== action.payload.id),
        total: state.total - 1
      }
    }

    case DELETE_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case CREATE_USER_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: [...state.users, action.payload],
        total: state.total + 1
      }
    }

    case CREATE_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    case UPDATE_USER_DOING: {
      return {
        ...state,
        loading: true
      }
    }

    case UPDATE_USER_SUCCESS: {
      const users = state.users.map(user => {
        if (user.id === action.payload.id) {
          user = {
            ...user,
            ...action.payload
          }
        }

        return user;
      });

      return {
        ...state,
        loading: false,
        users
      }
    }

    case UPDATE_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error.message
      }
    }

    default:
      return state;
  }
}

export default userReducer;
